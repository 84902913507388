import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, useSearchList } from 'hooks';
import {
  TextField,
  Card,
  Stack,
  Label,
  ProgressBar,
  Space,
  cr,
} from 'mw-style-react';

import ActorAvatar from '@control-front-end/common/components/ActorAvatar';
import globalIntl from 'globalIntl';

function ListWithSearch({
  onItemClick,
  actionType,
  listWrapperClassName,
  listClassName,
  itemClassName,
}) {
  const t = useIntl();
  const { list, loading, makeSearch, onScroll, searchParams } = useSearchList({
    actionType,
  });

  return (
    <Card className={listWrapperClassName}>
      <Space size="xxsmall" fullHeight>
        <Stack justifyContent="spaceBetween" size="xxsmall" fullHeight>
          <Stack
            className={listClassName}
            fullHeight
            onScroll={onScroll}
            size="xxsmall"
          >
            {cr(
              [
                !!list.length,
                list.map((item) => (
                  <Stack.H
                    alignItems="center"
                    className={itemClassName}
                    onClick={() => onItemClick(item)}
                    size="xxsmall"
                    fullWidth
                    key={item.id}
                  >
                    <ActorAvatar
                      size="small"
                      type="compact"
                      formType={item.formType}
                      formTitle={item.formTitle}
                      pictureUrl={item.pictureUrl}
                      colors={item.allColors}
                      status={item.status}
                      colorFilled
                    />
                    <Label
                      fontSize="small"
                      overflow="cut"
                      styles={{ height: '1.5em' }}
                      value={item.title}
                    />
                  </Stack.H>
                )),
              ],
              [loading, <ProgressBar type="circle" />],
              [
                !list.length && !loading,
                <Label
                  color={Label.COLOR.gray}
                  value={searchParams.search ? t(globalIntl.noResults) : ''}
                />,
              ]
            )}
          </Stack>
          <TextField
            bordered
            placeholder={t(globalIntl.searchByName)}
            unspaced
            onChange={({ value }) => makeSearch({ search: value.trim() })}
          />
        </Stack>
      </Space>
    </Card>
  );
}

ListWithSearch.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  listWrapperClassName: PropTypes.string,
  listClassName: PropTypes.string,
  itemClassName: PropTypes.string,
};

export default ListWithSearch;
