import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Label, Icon, Tooltip } from 'mw-style-react';
import cn from 'classnames';
import './Toggle.scss';

const Toggle = (props) => {
  const {
    id,
    children,
    isOpen,
    rightComponent,
    visible = true,
    isActive = false,
    title = '',
    tooltip = '',
    theme = 'grey',
    type = 'default',
    fontWeight = 'semibold',
    level = 1,
    onChange = () => {},
  } = props;
  const [open, setOpen] = useState(!!isOpen);
  const prevOpenRef = useRef();

  useEffect(() => {
    if (isOpen !== prevOpenRef.current) {
      setOpen(isOpen);
    }
    prevOpenRef.current = isOpen;
  }, [isOpen]);

  const renderDefault = () => (
    <div
      id={id}
      className="toggle"
      styleName={cn('le', theme, {
        hidden: !visible || !children,
        active: isActive,
      })}
    >
      <div
        styleName={cn('le__title', { hidden: !title })}
        onClick={() => {
          setOpen(!open);
          onChange(!open);
        }}
      >
        <Tooltip styleName="le__title__tooltip" value={tooltip}>
          <div styleName="le__title__label">
            <Icon
              styleName={cn('le__title__icon', { close: !open })}
              type="dropdown"
              size="micro"
            />
            <Label
              styleName="le__title__value"
              fontSize="medium"
              fontWeight={fontWeight}
              value={title}
            />
          </div>
        </Tooltip>
        <div styleName="le__title__rc" onClick={(e) => e.stopPropagation()}>
          {rightComponent}
        </div>
      </div>
      <div styleName={cn('le__wrap', { hidden: !open || !children })}>
        {children}
      </div>
    </div>
  );

  const renderSimulator = () => (
    <div
      styleName={cn('leSimulator', {
        hidden: !visible,
        compact: type === 'compact',
        secondary: level > 1,
      })}
    >
      <div
        styleName={cn('leSimulator__header', theme)}
        onClick={() => {
          setOpen(!open);
          onChange(!open);
        }}
      >
        <Icon
          styleName={cn('leSimulator__header__icon', { close: !open })}
          type="dropdown"
          size="micro"
        />
        <div styleName="leSimulator__header__title">{title}</div>
      </div>
      {open && children ? (
        <div styleName="leSimulator__content">{children}</div>
      ) : null}
    </div>
  );

  return type === 'default' ? renderDefault() : renderSimulator();
};

Toggle.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  tooltip: PropTypes.string,
  type: PropTypes.oneOf(['default', 'simulator', 'compact']),
  level: PropTypes.number,
  isOpen: PropTypes.bool,
  isActive: PropTypes.bool,
  theme: PropTypes.oneOf(['grey', 'white', 'dark']),
  fontWeight: PropTypes.oneOf(['normal', 'semibold']),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  rightComponent: PropTypes.element,
  visible: PropTypes.bool,
};

export default Toggle;
