import React, { useEffect, useRef, useState, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Utils, cr } from 'mw-style-react';
import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import cola from 'cytoscape-cola';
import edgehandles from 'cytoscape-edgehandles';
import bgGrid from 'control-cytoscape-bggrid';
import cytoscapeLasso from 'control-cytoscape-lasso';
import nodeHtmlLabel from 'cytoscape-node-html-label';
import popper from 'cytoscape-popper';
import cyCanvas from 'cytoscape-canvas';
import { isNumber, isNil } from 'lodash';

import AppUtils from '@control-front-end/utils/utils';
import { DAGREE_NODE_SEP } from '@control-front-end/common/constants/graphActors';
import { CUSTOM_EVENTS } from '@control-front-end/common/constants/graph';
import {
  snapGeomCoord,
  isIntersectionsAllowed,
} from '@control-front-end/utils/modules/utilsCellCoords';
import { PM_APP_NAME, GRAPH_CELL_SIZE } from 'constants';

import useGridSnap from './useGridSnap';
import NodeTitle from './NodeTitle';
import ExpandedActors from './ExpandedActors';
import Areas from './Areas';
import AreaTooltip from './AreaTooltip';
import GraphPopper from './GraphPopper';
import style from './GraphStyles';
import './GraphEngine.scss';

const applyPositionSnap = (node, position) =>
  isIntersectionsAllowed(node) ? position : snapGeomCoord(position);

try {
  nodeHtmlLabel(cytoscape);
  cytoscape.use(dagre);
  cytoscape.use(cola);
  cytoscape.use(edgehandles);
  cytoscape.use(popper);
  cytoscape.use(cytoscapeLasso);
  cyCanvas(cytoscape);
  cytoscape.use(bgGrid);
} catch (e) {
  console.log(e); // eslint-disable-line
}

const HEdgeType = 'hierarchy';

const setGraphViewCenter = (cy, position, newZoom) => {
  const zoom = newZoom === undefined ? cy.zoom() : newZoom;

  cy.animate(
    {
      pan: {
        x: cy.width() / 2 - position.x * zoom,
        y: cy.height() / 2 - position.y * zoom,
      },
      zoom,
    },
    { duration: 500 }
  );
};

const getZoomSettings = (adjustZoomToGraphSize) => {
  if (!adjustZoomToGraphSize) {
    return {
      zoom: 1,
      maxZoom: 1,
      minZoom: 0.2,
    };
  }

  // MacBook Pro 16" 2019 which has 3072x1920 resolution and 2 DPI in browser
  const REFERENCE_SCREEN_WIDTH = 1536;
  const REFERENCE_SCREEN_HEIGHT = 960;
  const REFERENCE_MIN_ZOOM = 0.1;
  const REFERENCE_MAX_ZOOM = 10;
  const width = window.screen.width;
  const height = window.screen.height;

  const zoom =
    (width / REFERENCE_SCREEN_WIDTH + height / REFERENCE_SCREEN_HEIGHT) / 2;
  return {
    zoom,
    minZoom: REFERENCE_MIN_ZOOM * zoom,
    maxZoom: REFERENCE_MAX_ZOOM * zoom,
  };
};

/**
 * Calculate edges curves control points distances depending on X and Y coordinates deltas
 */
const adjustEdgeCurve = (edge) => {
  const edgeXDelta =
    edge.source().renderedPosition('x') - edge.target().renderedPosition('x');
  const edgeYDelta =
    edge.source().renderedPosition('y') - edge.target().renderedPosition('y');
  const absXDelta = Math.abs(edgeXDelta);
  const absYDelta = Math.abs(edgeYDelta);
  const directionCoefficient = edgeYDelta > 0 ? 1 : -1;
  const bendCoefficient =
    0.25 *
    (absXDelta > absYDelta
      ? AppUtils.divideNotZero(absYDelta, absXDelta, 0)
      : AppUtils.divideNotZero(absXDelta, absYDelta, 0));
  const curvePointDistance =
    edgeXDelta * bendCoefficient * directionCoefficient;
  const controlPointDistances = [curvePointDistance, -curvePointDistance];
  edge.data('controlPointDistances', controlPointDistances.join(' '));
};

/**
 * Manage new or updated edges
 */
const manageAffectedEdges = (affectedEdges = []) =>
  affectedEdges.forEach(adjustEdgeCurve);

/**
 * Render of graph layer
 */
function GraphEngine(props) {
  const {
    graph,
    graphMountFlag,
    activeLayerId = null,
    els,
    currencyParams,
    readOnly,
    isLoaded,
    layersAreas,
    checkEdgeRestrictions = () => {},
    applyExtraStyles = () => {},
    edgeReconnect = () => {},
    handleDropNode = () => {},
    handleNodeDragStart = () => {},
    handleNodeDragEnd = () => {},
    handleNodePosition = () => {},
    handleKeyDown = () => {},
    handleRenameNode = () => {},
    handleSelectNode = () => {},
    handleSelectEdge = () => {},
    handleMakeActiveElement = () => {},
    handleLoadLayer = () => {},
    handleCopyActorLink = () => {},
    handleShowLinkedLayers = () => {},
    handleRemoveNode = () => {},
    handleContextMenu = () => {},
    handleQuickMenu = () => {},
    handleClickOutside = () => {},
    handleEhCancel = () => {},
    handleEhStart = () => {},
    handleEhComplete = () => {},
    handleGraphPosition = () => {},
    handleZoomLayout = () => {},
    handleGetCanvasCenter = () => {},
    handleAddedElements = () => {},
    handleLayoutStop = () => {},
    handleReconnect = () => {},
    panCenterNodeViewBox = () => {},
    handleBoxStart = () => {},
    handleBoxEnd = () => {},
    handleDoubleClickNode = () => {},
    handleDoubleClickOnField = () => {},
    handleSwitchNodeMouse = () => {},
    handleSaveActorLayerSettings = () => {},
    handleActionSound = () => {},
    handleTransferPopup = () => {},
    handleStateActorsPopup = () => {},
    handlePaste = () => {},
    layoutName = 'preset',
    lasso = false,
    isSingleLayerModel = false,
    fit = false,
    isMap = false,
    enableBgGrid = true,
    backgroundStyle = {},
    zoomingEnabled = true,
    adjustZoomToGraphSize = false,
    panningEnabled = true,
    onGraphInitialize,
    expandedNodesSettings = {},
    position: positionProp,
  } = props;
  const graphContainer = useRef();
  const graphMenu = useRef();
  const graphEdgeHandles = useRef();
  const layerArea = useRef();
  const bgPicture = useRef();
  const ehStarted = useRef();

  const showNodesCoordinates = useSelector(
    (state) => state.settings.showNodesCoordinates
  );

  const showNodesCoordinatesRef = useRef(showNodesCoordinates);
  showNodesCoordinatesRef.current = showNodesCoordinates;

  const queryParams = useMemo(
    () => Utils.getQueryParam(document.location.search),
    [document.location.search]
  );

  const position = useMemo(
    () =>
      cr(
        [
          !isNil(queryParams.x) && !isNil(queryParams.y),
          { x: Number(queryParams.x), y: Number(queryParams.y) },
        ],
        [isNumber(positionProp?.x) && isNumber(positionProp?.y), positionProp]
      ),
    [positionProp, queryParams.x, queryParams.y]
  );

  const graphPopper = GraphPopper({
    graph,
    graphContainer,
    graphEdgeHandles,
    edgeReconnect,
    ehStarted,
    readOnly,
    isSingleLayerModel,
  });
  const [edgesList, setEdgesList] = useState([]);

  /**
   * Canvas center calculation
   */
  const getCenter = () => {
    if (!graph.current) return;
    const { x1, y1, w, h } = graph.current.extent();
    handleGetCanvasCenter({
      graph: graph.current,
      extra: { x: x1 + w / 2 - 60, y: y1 + h / 2 - 30 },
    });
  };

  /**
   * Get elements selected on graph
   */
  const getSelectedElements = () => {
    const selEls = graph.current.elements('node:selected, edge:selected');
    return selEls.map((i) => {
      const data = i.data();
      if (data.type === 'node' && !data.position) {
        data.position = i.position();
      }
      return data;
    });
  };

  /**
   * Get coordinates of selected area center
   */
  const getSelectedCenter = () => {
    const selEls = graph.current.elements('node:selected, edge:selected');
    const bb = selEls.boundingBox();
    return { x: bb.x1 + bb.w / 2, y: bb.y1 + bb.h / 2 };
  };

  /**
   * Check for loops in selected nodes
   */
  const hasLoops = () => {
    const roots = graph.current.elements('node:selected').roots();
    if (!roots.length) return true;

    function findLoop(node, visited) {
      const outgoers = node.outgoers('node');
      if (!outgoers.length) return;
      for (const n of outgoers) {
        const id = n.data('actorId');
        const vs = visited.includes(id);
        visited.push(id);
        if (vs) return;
        findLoop(n, visited);
      }
    }

    const res = [];
    for (const node of roots) {
      const visited = [];
      findLoop(node, visited);
      res.push(AppUtils.hasDuplicates(visited));
    }
    return res.includes(true);
  };

  /**
   * Hotkeys
   */
  const handleKeyPressDown = (e) => {
    const nodes = getSelectedElements();
    handleKeyDown({ e, graph: graph.current, extra: { nodes, hasLoops } });
  };

  /**
   * Add elements on graph
   */
  const addElsToGraph = (addEls) => {
    for (const el of addEls) {
      const { id, type, parent, source, target } = el.data;
      const f = graph.current.$(`#${id}`);
      if (!f.empty()) continue;
      if (type === 'edge') {
        const sN = graph.current.$(`#${source}`);
        const tN = graph.current.$(`#${target}`);
        if (sN.empty() || tN.empty()) continue;
        graph.current.add(el);
      } else if (type === 'node' && parent) {
        graph.current.add(el);
        const fP = graph.current.$(`#${parent}`);
        graph.current.$(`#${id}`).move({ target: fP });
      } else {
        graph.current.add(el);
      }
    }
  };

  /**
   * Manage new or updated nodes
   */
  const manageUpdatedNodes = (updatedNodes = [], withBalance = false) => {
    if (!updatedNodes.length) return;
    updatedNodes.forEach((node) => {
      const nodeEl = graph.current.$(`#${node.data.id}`)[0];
      if (!nodeEl) return;
      if (withBalance && 'balance' in node.data) {
        nodeEl.scratch(
          'balanceFormatted',
          AppUtils.formattedAmount(node.data.balance, currencyParams)
        );
      }
      // you can't move nodes on read-only layer and if node is pinned or read-only state
      // (no access to update polygon in actor data)
      const readOnlyState = node.data.isStateMarkup && !node.data.privs?.modify;
      const lockedPosition =
        readOnly || node.data.layerSettings?.pin || readOnlyState;
      const canGrab = nodeEl.grabbable();
      if (canGrab === !lockedPosition) return;
      if (lockedPosition) {
        nodeEl.ungrabify();
        nodeEl.unselectify();
      } else {
        nodeEl.grabify();
        nodeEl.selectify();
      }
    });
  };

  /**
   * Actions after graph update
   */
  const afterManageEls = () => {
    // Set special styles for graph elements
    applyExtraStyles();
    if (fit) {
      graph.current.fit(graph.current.elements(), 10);
    }
    const nodes = graph.current.elements('node');
    labelVisibility(nodes); // eslint-disable-line
    fixedSwitchBox(); // eslint-disable-line
  };

  /**
   * Management of new graph elements
   */
  const manageElementsGraph = (newEls) => {
    const updatedNodes = newEls.filter((el) => el.data.type === 'node');
    // If just one new element has replaceAll flag,
    // we replace the whole graph
    if (newEls[0] && newEls[0].replaceAll) {
      graph.current.elements().remove();
      graph.current.json({ elements: newEls });
      if (layoutName === 'dagre') {
        graph.current
          .elements()
          .layout({
            name: 'dagre',
            nodeSep: DAGREE_NODE_SEP,
            transform: applyPositionSnap,
          })
          .run();
      } else if (layoutName === 'concentric') {
        graph.current
          .elements()
          .layout({
            name: 'concentric',
            minNodeSpacing: GRAPH_CELL_SIZE,
            transform: applyPositionSnap,
          })
          .run();
      }
      const affectedEdges = graph.current
        .elements('edge')
        .filter(
          (edge) => !Object.isFrozen(edge.data()) && !edge.hasClass('transfers')
        );
      manageUpdatedNodes(updatedNodes);
      manageAffectedEdges(affectedEdges);
      afterManageEls();
      return;
    }
    // If it's vertical layer without coordinates, auto set nodes positions
    const vLayerNodes = newEls.filter(
      (el) => el.data.type === 'node' && el.data.isLayer
    );
    const newWithoutPosition = vLayerNodes.every(
      (node) => !node.position && node.status === 'new'
    );
    if (vLayerNodes.length && newWithoutPosition) {
      graph.current
        .elements()
        .layout({
          name: 'dagre',
          nodeSep: DAGREE_NODE_SEP,
          transform: applyPositionSnap,
        })
        .run();
    }
    const groupEls = AppUtils.groupBy(newEls, 'status');
    if (!Object.keys(groupEls).length) {
      graph.current.elements().unselect();
    }
    // Add actors to graph
    if (groupEls.new) addElsToGraph(groupEls.new);
    // Remove actors from graph
    (groupEls.removed || []).forEach((i) => {
      setTimeout(() => {
        graph.current.$(`#${i.data.id}`).remove();
      }, 0);
    });
    // Update actor on graph
    (groupEls.updated || []).forEach((i) => {
      const el = graph.current.$(`#${i.data.id}`);
      el.move({ parent: i.data.parent });
      const changes = AppUtils.nonDeepObjectDiff(el.data(), i.data);
      delete changes.selected;
      delete changes.position;
      el.data(changes);
      el.classes(i.classes);
    });
    const prevAddedEdgesIds = (groupEls.new || [])
      .filter((edge) => edgesList.includes(edge.id))
      .map((edge) => edge.data.id);
    const removedEdgesIds = (groupEls.removed || []).map(
      (edge) => edge.data.id
    );
    const newEdges = (groupEls.new || []).filter(
      (el) => el.data.type === 'edge'
    );
    const newEdgesList = [];
    for (const { id } of newEdges) {
      const edge = graph.current.$(`#${id}`)[0];
      if (!edge || edgesList.includes(id)) continue;
      if (!edge.hasClass('transfers')) {
        graphPopper.edgeLinkedActorPopper(edge);
      }
      newEdgesList.push(id);
      edge.on('remove', (event) => {
        // Remove edge from list
        const removeId = event.target.data('id');
        setEdgesList(edgesList.filter((item) => item.id !== removeId));
      });
    }
    setEdgesList(edgesList.concat(newEdgesList));
    manageUpdatedNodes(updatedNodes, true);
    const updatedNodesIds = updatedNodes.map((node) => node.data.id);
    const affectedEdges = graph.current
      .elements('edge')
      .filter(
        (edge) =>
          !Object.isFrozen(edge.data()) &&
          !prevAddedEdgesIds.includes(edge.data('id')) &&
          !removedEdgesIds.includes(edge.data('id')) &&
          !edge.hasClass('transfers') &&
          (updatedNodesIds.includes(edge.data('source')) ||
            updatedNodesIds.includes(edge.data('target')))
      );
    manageAffectedEdges(affectedEdges);
    afterManageEls();
  };

  /**
   * Expansion of new edges handles
   */
  const edgeHandles = () => {
    graphEdgeHandles.current = graph.current.edgehandles({
      preview: false,
      snap: false,
      canConnect: (sourceNode, targetNode) => !sourceNode.same(targetNode),
    });
  };

  /**
   * Select edge
   */
  const selectEdge = () => {
    graph.current.on('tap', 'edge', (el) => {
      handleSelectEdge({ e: el, graph: graph.current });
      const edge = el.target;
      const edgePrivs = edge.data('privs') || {};
      if (edgePrivs.modify && !isMap) {
        graphPopper.edgeReconnectPopper(edge, 'source');
        graphPopper.edgeReconnectPopper(edge, 'target');
      }
    });
  };

  /**
   * Select node and linked elements
   */
  const highlightNode = (node) => {
    const linkedEls = node.neighborhood();
    const exclusions = graph.current.elements(
      `.eh-handle, node[id="${node.data('parent')}"]`
    );
    graph.current
      .elements()
      .difference(exclusions)
      .difference(linkedEls)
      .addClass('semitransp');
    linkedEls.union(node).union(node.parent()).removeClass(['semitransp']);
  };

  /**
   * Select node
   */
  const selectNode = () => {
    graph.current.on('onetap', 'node', (e) => {
      const { target } = e;
      const data = target.data();
      if ((data && data.isNonInteractive) || target.hasClass('eh-handle'))
        return;
      handleSelectNode({ e, graph: graph.current });
      highlightNode(target);
      if (data.isTemplate || data.locked || data.static) return;
      const renderedPosition = target.renderedPosition();
      handleQuickMenu({
        extra: { target, renderedPosition, data },
      });
    });
  };

  /**
   * Context tap handler
   */
  const cxtTap = () => {
    graph.current.on('cxttap', (e) => {
      const { position, renderedPosition, target } = e;
      const data = target.data();
      if (data && data.isNonInteractive) return;
      handleQuickMenu({ extra: null });
      const center = getSelectedCenter();
      if (target === graph.current) {
        if (readOnly) return;
        handleContextMenu({
          e,
          graph: graph.current,
          extra: { position, renderedPosition, data, center, hasLoops },
        });
        return;
      }
      graph.current.elements('node[?isNonInteractive]:selected').unselect();
      const elements = getSelectedElements();
      const isBoxSelection = elements.length > 1;
      const tapInBoxSelection =
        isBoxSelection && elements.find((el) => el.id === data.id);
      if (tapInBoxSelection && !elements[0].isAutoLayerRoot) {
        if (readOnly) return;
        handleContextMenu({
          e,
          graph: graph.current,
          extra: {
            id: target.id(),
            renderedPosition,
            position,
            data,
            center,
            elements,
            hasLoops,
          },
        });
        return;
      }
      if (isBoxSelection && !tapInBoxSelection) {
        graph.current.elements().unselect();
      }
      if (
        (target.isNode() && !target.hasClass('eh-handle')) ||
        target.isEdge()
      ) {
        if (target.data().locked) return;
        const extra = {
          id: target.id(),
          renderedPosition,
          position,
          data,
          center,
          elements: [target.data()],
          hasLoops,
        };
        handleContextMenu({ e, extra, graph: graph.current });
        return;
      }
      handleContextMenu({ e, graph: graph.current, extra: null });
    });
  };

  /**
   * Empty space click handler
   */
  const outsideClick = () => {
    graph.current.on('tap', (el) => {
      handleContextMenu({ e: el, graph: graph.current, extra: null });
      if (el.target === graph.current || el.target.isEdge()) {
        handleQuickMenu({ extra: null });
        graph.current
          .elements()
          .removeClass(['highlight', 'semitransp', 'target']);
      }
      if (el.target === graph.current) {
        handleClickOutside({
          e: el,
          graph: graph.current,
          extra: { nodes: graph.current.$('node:selected') },
        });
        graph.current.elements().unselect();
      }
      if (window.frameElement) {
        window.parent.postMessage(
          {
            appName: PM_APP_NAME,
            type: 'GRAPH_CONTENT_CLICK',
            layerId: activeLayerId,
          },
          window.parent.origin
        );
      }
    });
  };

  /**
   * Node double click handler
   */
  const doubleClickNode = () => {
    graph.current.on('dbltap', 'node', (e) => {
      const node = e.target;
      const nodeData = e.target.data();
      if (nodeData.isNonInteractive || nodeData.locked) return;
      if (node.hasClass('switchBoxItem') || node.hasClass('switchBox')) return;
      handleDoubleClickNode({ e, graph: graph.current });
      handleQuickMenu({ extra: null });
    });
  };

  /**
   * Empty space double click handler
   */
  const doubleClickOnField = () => {
    graph.current.on('dbltap', (e) => {
      if (e.target !== graph.current) return;
      handleDoubleClickOnField({ e, graph: graph.current });
    });
  };

  /**
   * Create node when edge handle released on empty space
   */
  const createNode = () => {
    graph.current.on('ehcancel', (e, sourceNode) => {
      graph.current.elements().unselect();
      const { position: p1 } = e;
      let p2;
      let editableEdge;
      if (edgeReconnect.current) {
        const { id: edgeId, side } = edgeReconnect.current;
        editableEdge = graph.current.$(`#${edgeId}`);
        const sideNode =
          side === 'target' ? editableEdge.source() : editableEdge.target();
        p2 = sideNode.position();
      } else {
        p2 = sourceNode.position();
      }
      const len = Math.sqrt((p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2);
      if ((p1.x === 0 && p1.y === 0) || len < 50) {
        if (editableEdge) {
          editableEdge.removeClass('hide');
          edgeReconnect.current = null;
        }
        return;
      }
      handleEhCancel({
        e,
        graph: graph.current,
        extra: { sourceNode, position: p1 },
      });
    });
  };

  /**
   * Highlight node if edge to id cannot be created
   */
  const nodeMouseOver = () => {
    graph.current.on('ehhoverover', (event, sourceNode, targetNode) => {
      const targetPrivs = targetNode.data('privs') || {};
      const isForbidden = checkEdgeRestrictions({ sourceNode, targetNode });
      const edgeSide = edgeReconnect.current
        ? edgeReconnect.current.side
        : 'target';
      const cycleEdges =
        edgeSide === 'target'
          ? targetNode.edgesTo(sourceNode)
          : sourceNode.edgesTo(targetNode);
      const isCycleEdge = cycleEdges.filter(
        (ele) => ele.data('edgeType') === HEdgeType
      ).length;
      const isSameNode =
        targetNode.data('actorId') === sourceNode.data('actorId');
      if (!isCycleEdge && targetPrivs.modify && !isForbidden && !isSameNode) {
        return;
      }
      targetNode.addClass('forbidden');
    });
  };

  /**
   * Turn of node highlight when focus lost
   */
  const nodeMouseOut = () => {
    graph.current.on('ehhoverout', (event, sourceNode, targetNode) => {
      targetNode.removeClass('forbidden');
    });
  };

  /**
   * Events mouseover\mouseout for switch-box actors
   */
  const switchNodeMouse = () => {
    graph.current.on('mouseover mouseout', '.switchBoxItem', (e) => {
      handleSwitchNodeMouse({ e, graph: graph.current });
    });
    graph.current.on('mouseover mouseout', '.switchBox', (e) => {
      handleSwitchNodeMouse({ e, graph: graph.current });
    });
  };

  /**
   * Handle mouse down to create new edge
   */
  const edgeMouseDown = () => {
    graph.current.on('ehstart', () => {
      handleEhStart();
    });
  };

  /**
   * Create new edge
   */
  const createEdge = () => {
    graph.current.on('ehcomplete', (e, sourceNode, targetNode, addedEles) => {
      const targetPrivs = targetNode.data('privs') || {};
      const cycleEdges = targetNode.edgesTo(sourceNode);
      const isCycleEdge = cycleEdges.filter(
        (ele) => ele.data('edgeType') === HEdgeType
      ).length;
      const isForbidden = checkEdgeRestrictions({ sourceNode, targetNode });
      const isSameNode =
        targetNode.data('actorId') === sourceNode.data('actorId');
      const alreadyLinked =
        sourceNode
          .edgesTo(targetNode)
          .filter((ele) => ele.data('edgeType') === HEdgeType).length === 2;
      const editableEdge = edgeReconnect.current
        ? graph.current.$(`#${edgeReconnect.current.id}`)
        : null;
      if (
        isCycleEdge ||
        !targetPrivs.modify ||
        isForbidden ||
        isSameNode ||
        alreadyLinked
      ) {
        addedEles.remove();
        targetNode.removeClass('forbidden');
        if (editableEdge) {
          const side = edgeReconnect.current.side;
          const oldConnectedNode = editableEdge[side]();
          if (alreadyLinked || oldConnectedNode.id() === targetNode.id()) {
            editableEdge.removeClass('hide');
          } else if (isSameNode || oldConnectedNode.id() !== targetNode.id()) {
            handleReconnect();
          }
          edgeReconnect.current = null;
        }
        return;
      }
      addedEles.remove();
      const name = editableEdge ? editableEdge.data('name') : '';
      handleEhComplete({
        e,
        graph: graph.current,
        extra: { sourceNode, targetNode, name },
      });
    });
  };

  /**
   * Save all nodes positions when leaving layer
   */
  const saveGraphPosition = (firstPositions = false) => {
    const dataPos = [];
    graph.current.$('node').forEach((node) => {
      const type = node.data('type');
      const rO = node.data('readOnly');
      if (!type || type === 'edge' || rO) return;
      dataPos.push({ id: node.id(), type, position: node.position() });
    });
    if (!firstPositions) handleActionSound();
    handleGraphPosition({
      graph: graph.current,
      extra: { nodes: dataPos },
    });
  };

  /**
   * Layout re-render stop handler
   */
  const layoutStopped = () => {
    graph.current.on('layoutstop', () => {
      saveGraphPosition();
    });
  };

  /**
   * Check if node in viewport
   */
  const isNodeOutOfView = (node) => {
    try {
      if (!node) return true;
      const nodeBB = node.boundingBox();
      const graphPan = graph.current.extent();
      return (
        nodeBB.x1 < graphPan.x1 ||
        nodeBB.x2 > graphPan.x2 ||
        nodeBB.y1 < graphPan.y1 ||
        nodeBB.y2 > graphPan.y2
      );
    } catch (e) {
      // console.log(e); // eslint-disable-line
    }
  };

  /**
   * Set node labels visibility
   */
  const labelVisibility = (nodes) => {
    for (const node of nodes) {
      if (isNodeOutOfView(node) && !node.hasClass('stateMarkup')) {
        node.removeClass('visibleLabel');
      } else {
        node.addClass('visibleLabel');
      }
    }
  };

  /**
   * Toggle actors labels depending on zoom
   */
  const toggleLabelsOnZoom = (zoom) => {
    if (!graphContainer.current) return;
    const nodeTitleEl = graphContainer.current.querySelector('.nodeTitle');
    if (!nodeTitleEl) return;
    const titlesContainer = nodeTitleEl.parentElement.parentElement;
    if (!titlesContainer) return;
    if (zoom <= 0.2) {
      titlesContainer.style.display = 'none';
    } else {
      titlesContainer.style.display = 'block';
    }
  };

  /**
   * Set switch-box fixed position
   */
  const fixedSwitchBox = () => {
    const extent = graph.current.extent();
    const relInit = { x: extent.x1 + 12, y: extent.y2 - 12 };
    const nodes = graph.current.elements('.switchBoxItem');
    nodes.unlock();
    nodes.forEach((node, index) => {
      const p = { ...relInit, x: relInit.x + index * 24 };
      node.position(p);
    });
    nodes.lock();
  };

  /**
   * Zoom and resize of graph
   */
  const zoomLayout = () => {
    graph.current.on('pan dragpan zoom resize', (e) => {
      const zoom = graph.current.zoom();
      const nodes = graph.current.elements('node');
      toggleLabelsOnZoom(zoom);
      fixedSwitchBox();
      getCenter();
      const f = Utils.debounce(labelVisibility, 200);
      f(nodes);
      handleZoomLayout({
        e,
        graph: graph.current,
        extra: { zoom, nodes, extent: graph.current.extent() },
      });
    });
  };

  /**
   * Node position change handler
   */
  const nodePosition = () => {
    graph.current.on('position', (e) => {
      handleNodePosition({ e, graph: graph.current });
    });
  };

  /**
   * Node drag start handler
   */
  const nodeDragStart = () => {
    graph.current.on('grab', (e) => {
      if (!e.target.isNode()) return;
      e.target.scratch('prevPosition', { ...e.target.position() });
      handleNodeDragStart({ e, graph: graph.current });
    });
  };

  const nodeBulkDrag = () => {
    graph.current.on(CUSTOM_EVENTS.drag_bulk, (e, nodes) =>
      nodes.forEach((node) => manageAffectedEdges(node.connectedEdges()))
    );
  };

  /**
   * Save node position on drag end
   */
  const nodeDragEnd = () => {
    graph.current.on(CUSTOM_EVENTS.dragfree_bulk, (e, nodes) => {
      handleActionSound();
      handleNodeDragEnd(
        {
          e,
          graph: graph.current,
          extra: {
            nodes: nodes.map((node) => ({
              id: node.id(),
              type: node.data('type'),
              position: node.position(),
            })),
          },
        },
        nodes
      );
    });
  };

  /**
   * Click on actor's label
   */
  const nodeLabelClick = (e) => {
    const nodeTitle = e.target.closest('.nodeTitle');
    if (!nodeTitle) return;
    const target = graph.current.$(`#${nodeTitle.dataset.id}`);
    target.select();
    if (target.data('locked')) return;
    handleSelectNode({ e: { target }, graph: graph.current });
  };

  /**
   * Start of area selecting on graph
   */
  const boxStart = () => {
    graph.current.on('boxstart', (e) => {
      handleBoxStart(e);
    });
  };

  /**
   * End of area selecting on graph
   */
  const boxEnd = () => {
    graph.current.on('boxend', (e, polygon) => {
      const { position, renderedPosition, originalEvent } = e;
      handleBoxEnd({
        graph: graph.current,
        extra: {
          polygon,
          zoom: graph.current.zoom(),
          hasLoops,
          elements: getSelectedElements(),
          center: getSelectedCenter(),
          position,
          renderedPosition,
          originalEvent,
        },
      });
    });
  };

  /**
   * Override standard browser drag&drop behavior, allow dragging
   */
  const allowDrop = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
  };

  /**
   * Actor is dragged to graph (add/create a new one)
   */
  const handleDropActor = (e) => {
    e.stopPropagation();
    const data = e.dataTransfer.getData('text');
    const dropData = JSON.parse(data);
    if (!dropData.actors || !dropData.actors.length) return;
    const pan = graph.current.pan();
    const zoom = graph.current.zoom();
    const pageX = dropData.pageX || e.pageX;
    const pageY = dropData.pageY || e.pageY;
    const correctX = graphContainer.current.getBoundingClientRect().left;
    const mainActorPos = dropData.actors[0].position || { x: 0, y: 0 };
    for (const actor of dropData.actors) {
      const diffX = actor.position ? mainActorPos.x - actor.position.x : 0;
      const diffY = actor.position ? mainActorPos.y - actor.position.y : 0;
      actor.position = snapGeomCoord({
        x: (pageX - pan.x - diffX - correctX) / zoom,
        y: (pageY - pan.y - diffY) / zoom,
      });
    }
    handleDropNode({
      action: dropData.action,
      actors: dropData.actors,
      form: dropData.form,
    });
    return false;
  };

  /**
   * Handle parent window messages in iframe
   */
  const handleParentMessage = ({ data }) => {
    switch (data.type) {
      case 'PAN_GRAPH':
        const { x, y } = data.payload;
        graph.current.animate({
          panBy: { x, y },
          duration: 100,
          easing: 'ease-in-expo',
        });
        break;
      case 'MIN_MAX_IFRAME':
        setTimeout(() => {
          graph.current.fit();
        }, 300);
        break;
      case 'TOGGLE_VERTICAL_LINKS':
        const switchBoxEdges = graph.current.elements('edge[?switchBox]');
        if (data.payload.showLinks) {
          switchBoxEdges.removeClass('hide');
        } else {
          switchBoxEdges.addClass('hide');
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const { zoom, minZoom, maxZoom } = getZoomSettings(
      adjustZoomToGraphSize && !AppUtils.isIframe()
    );
    const elsNodes = els.filter((el) => el.data.type === 'node');
    const noPositions = elsNodes.every((n) => !n.position);
    const graphEls = elsNodes.length ? els : [];

    graph.current = cytoscape({
      layout: {
        name: noPositions ? 'dagre' : layoutName,
        nodeSep: DAGREE_NODE_SEP,
        avoidOverlap: true,
        fit,
        transform: applyPositionSnap,
        stop: (e) => {
          setTimeout(() => {
            handleLayoutStop({ e, graph: graph.current });
            // Save nodes start positions
            if (noPositions) saveGraphPosition(noPositions);
          }, 100);
        },
      },
      style,
      elements: graphEls,
      zoom,
      maxZoom,
      minZoom,
      textureOnViewport: els.length > 10000,
      container: graphContainer.current,
    });

    graph.current.nodeHtmlLabel([
      {
        query: '.visibleLabel',
        halign: 'left',
        valign: 'top',
        halignBox: 'right',
        valignBox: 'bottom',
        tpl(data) {
          const elD = graph.current.$(`#${data.id}`);
          const customData = {};
          if (layoutName !== 'dagre') {
            const countOneLevel = elD.outgoers('node[group="nodes"]');
            customData.countOneLevel = countOneLevel.length;
          }
          const sel = graph.current.$('node:selected');
          customData.polygon = elD.hasClass('stateMarkup')
            ? data.polygon
            : null;
          customData.semitransp = elD.hasClass('semitransp');
          customData.hide =
            elD.hasClass('hide') ||
            data.isLayerArea ||
            data.layerSettings?.expand ||
            isNodeOutOfView(elD);
          customData.selected = sel.id() === data.id;
          const updatedBalance = elD.scratch('balanceFormatted');
          customData.balanceFormatted = !AppUtils.isUndefined(updatedBalance)
            ? updatedBalance
            : data.balanceFormatted;
          customData.width = elD.width();
          customData.readOnly = !data.privs?.modify;
          return ReactDOMServer.renderToStaticMarkup(
            <NodeTitle
              showNodesCoordinates={showNodesCoordinatesRef.current}
              {...data}
              {...customData}
            />
          );
        },
      },
      {
        query: '.switchBoxItem',
        halign: 'center',
        valign: 'center',
        halignBox: 'center',
        valignBox: 'center',
        tpl(data) {
          return `<span style="font-size: 12px;cursor: pointer">
          ${data.title.charAt(0)}
          </span>`;
        },
      },
    ]);
    // Background grid
    if (enableBgGrid) {
      graph.current.bgGrid({
        gridSpacing: GRAPH_CELL_SIZE,
        gridColor: '#D0E4F5',
        snapToGridCenter: false,
        gridStyle: 'lines',
        style: {
          zIndex: -1,
          backgroundColor: '#ffffff', // "mainBg" from the variables.scss
          ...backgroundStyle,
        },
      });
    }

    getCenter();
    edgeHandles();
    graphPopper.edgeHandlesPopper();
    graphPopper.edgeAddTransferPopper(handleTransferPopup);
    graphPopper.stateNodePopper(handleStateActorsPopup);
    doubleClickNode();
    doubleClickOnField();
    selectNode();
    selectEdge();
    cxtTap();
    outsideClick();
    createNode();
    createEdge();
    edgeMouseDown();
    nodeMouseOver();
    nodeMouseOut();
    switchNodeMouse();
    nodeDragStart();
    nodeDragEnd();
    nodeBulkDrag();
    nodePosition();
    layoutStopped();
    zoomLayout();
    boxStart();
    boxEnd();
    // Set actor label click events listeners
    graphContainer.current.addEventListener('click', nodeLabelClick);
    graphContainer.current.addEventListener('drop', handleDropActor);
    graphContainer.current.addEventListener('dragover', allowDrop);
    graphMountFlag.current = true;
    // Center graph
    if (position) setGraphViewCenter(graph.current, position);
    else if (isSingleLayerModel)
      graph.current.fit(graph.current.elements(), 10);
    else {
      graph.current.pan({
        x: graph.current.width() / 2,
        y: graph.current.height() / 2,
      });
    }

    // Turn lasso on
    graph.current.lassoSelectionEnabled(lasso);
    layerArea.current = graph.current.cyCanvas({
      zIndex: 1,
    });
    bgPicture.current = graph.current.cyCanvas({
      zIndex: 0,
    });

    onGraphInitialize?.({ graph: graph.current });

    return () => {
      graphMountFlag.current = false;
      if (graphContainer.current) {
        graphContainer.current.removeEventListener('click', nodeLabelClick);
        graphContainer.current.removeEventListener('drop', handleDropActor);
        graphContainer.current.removeEventListener('dragover', allowDrop);
      }
      if (layoutName === 'dagre') saveGraphPosition();
      if (graph.current) graph.current.destroy();
      if (graphMenu.current) graphMenu.current.destroy();
    };
  }, []);

  // Handles grid snapping on nodes drag
  useGridSnap(graph.current);

  useEffect(() => {
    graph.current.userZoomingEnabled(zoomingEnabled);
  }, [zoomingEnabled]);

  useEffect(() => {
    graph.current.userPanningEnabled(panningEnabled);
  }, [panningEnabled]);

  useEffect(() => {
    if (graph.current) {
      manageElementsGraph(els);
      setTimeout(() => {
        handleAddedElements({ graph: graph.current });
      }, 0);
    }
  }, [els]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPressDown, false);
    document.addEventListener('paste', handlePaste);
    if (window.frameElement) {
      window.addEventListener('message', handleParentMessage);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyPressDown);
      document.removeEventListener('paste', handlePaste);
      if (window.frameElement) {
        window.removeEventListener('message', handleParentMessage);
      }
    };
  }, []);

  useEffect(() => {
    const stopHandle = () => {
      if (edgeReconnect) graphEdgeHandles.current.stop();
    };
    document.addEventListener('mouseup', stopHandle, false);
    return () => {
      document.removeEventListener('mouseup', stopHandle);
    };
  }, [edgeReconnect]);

  useEffect(() => {
    graph.current.lassoSelectionEnabled(lasso);
  }, [lasso]);

  return (
    <>
      <div
        ref={graphContainer}
        styleName={cn('ge', { hide: !isLoaded })}
        onContextMenu={(e) => e.preventDefault()}
      >
        {layerArea.current && layersAreas && layoutName !== 'dagre' ? (
          <>
            <Areas
              key={layersAreas.lastUpdate}
              layersAreas={layersAreas}
              graph={graph.current}
              layer={layerArea.current}
            />
            <AreaTooltip
              graph={graph.current}
              handleSelectNode={handleSelectNode}
            />
          </>
        ) : null}
      </div>
      {graph.current ? (
        <ExpandedActors
          graph={graph.current}
          els={els}
          isSingleLayerModel={isSingleLayerModel}
          isLayerReadOnly={readOnly}
          handleMakeActiveElement={handleMakeActiveElement}
          handleLoadLayer={handleLoadLayer}
          handleShowLinkedLayers={handleShowLinkedLayers}
          handleCopyActorLink={handleCopyActorLink}
          handleRemoveNode={handleRemoveNode}
          handleNodeDragEnd={handleNodeDragEnd}
          handleSaveActorLayerSettings={handleSaveActorLayerSettings}
          handleRenameNode={handleRenameNode}
          panCenterNodeViewBox={panCenterNodeViewBox}
          handleClickOutside={handleClickOutside}
          {...expandedNodesSettings}
        />
      ) : null}
    </>
  );
}

GraphEngine.propTypes = {
  graph: PropTypes.object,
  graphMountFlag: PropTypes.object,
  layoutName: PropTypes.oneOf(['preset', 'dagre', 'concentric']),
  els: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isMap: PropTypes.bool,
  lasso: PropTypes.bool,
  isSingleLayerModel: PropTypes.bool,
  fit: PropTypes.bool,
  zoomingEnabled: PropTypes.bool,
  adjustZoomToGraphSize: PropTypes.bool,
  panningEnabled: PropTypes.bool,
  enableBgGrid: PropTypes.bool,
  layersAreas: PropTypes.object,
  picture: PropTypes.string,
  pictureOpacity: PropTypes.number,
  checkEdgeRestrictions: PropTypes.func,
  applyExtraStyles: PropTypes.func,
  handleKeyDown: PropTypes.func,
  handleDropNode: PropTypes.func,
  handleActionSound: PropTypes.func,
  handleNodePosition: PropTypes.func,
  handleNodeDragStart: PropTypes.func,
  handleNodeDragEnd: PropTypes.func,
  handleDoubleClickNode: PropTypes.func,
  handleDoubleClickOnField: PropTypes.func,
  handleRenameNode: PropTypes.func,
  handleSelectNode: PropTypes.func,
  handleSelectEdge: PropTypes.func,
  handleContextMenu: PropTypes.func,
  handleQuickMenu: PropTypes.func,
  handleClickOutside: PropTypes.func,
  handleEhCancel: PropTypes.func,
  handleEhComplete: PropTypes.func,
  handleGraphPosition: PropTypes.func,
  handleGetCanvasCenter: PropTypes.func,
  handleZoomLayout: PropTypes.func,
  handleAddedElements: PropTypes.func,
  handleLayoutStop: PropTypes.func,
  handleReconnect: PropTypes.func,
  handleBoxStart: PropTypes.func,
  handleBoxEnd: PropTypes.func,
  panCenterNodeViewBox: PropTypes.func,
  handleSwitchNodeMouse: PropTypes.func,
  handleSaveActorLayerSettings: PropTypes.func,
  edgeReconnect: PropTypes.object,
  handleMakeActiveElement: PropTypes.func,
  handleLoadLayer: PropTypes.func,
  handleShowLinkedLayers: PropTypes.func,
  handleCopyActorLink: PropTypes.func,
  handleRemoveNode: PropTypes.func,
  handleTransferPopup: PropTypes.func,
  handleStateActorsPopup: PropTypes.func,
  onGraphInitialize: PropTypes.func,
  expandedNodesSettings: PropTypes.object,
  handlePaste: PropTypes.func,
};

export default GraphEngine;
