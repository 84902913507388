import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  SendForm,
  forwardRef,
  Button,
  Label,
  Cursor,
  Utils,
  Popover,
} from 'mw-style-react';
import cn from 'classnames';
import unionBy from 'lodash/unionBy';
import {
  useModal,
  useOutsideClick,
  useURLSettings,
  useCachedSnippets,
} from 'hooks';
import { SmartChipPopup, SnippetsPopup, CustomBar } from 'components';
import getUrlAttachments from '@control-front-end/utils/getUrlAttachments';
import AppUtils from '@control-front-end/utils/utils';
import { SEARCH_SMART_CHIP_REGEXP } from '@control-front-end/common/constants/regExp';
import { GET_ACTOR_ATTACHMENTS } from 'constants';
import {
  WS_EXTERNAL_PACKET,
  EXTERNAL_PACKET_ACTION,
} from '@control-front-end/common/constants/reactions';
import { GET_SCRIPT } from '@control-front-end/common/constants/scripts';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import { REACTIONS_MODE } from '@control-front-end/common/constants/reactionsMode';
import { ReactionOption } from '@control-front-end/common/constants/graphActors';
import {
  checkUserPermissions,
  getFileMimeTypes,
} from '@control-front-end/app/src/selectors';
import ScriptChip from './ScriptChip';
import scss from './ReactionForm.scss';

const ForwardSendForm = forwardRef(SendForm);

const DEFAULT_FORM_STATE = {
  message: '',
  files: [],
  sign: false,
  rating: 0,
};

function ReactionForm(props) {
  const {
    id,
    actorId,
    reactionId,
    value,
    formLabel = '',
    placeholder,
    cancelButtonLabel,
    sendButtonLabel,
    buttonsUnderForm,
    users,
    sign,
    reject,
    done,
    rating,
    quote,
    form,
    editorRef,
    contentRef,
    closed,
    mode,
    useSnippets = true,
    autoFocus = false,
    onChange = () => {},
    onCancel = () => {},
    onClearQuote = () => {},
    onClickOutside = () => {},
    onSubmit,
  } = props;
  const dispatch = useDispatch();
  const formRef = useRef();
  const defaultEditorRef = useRef();
  const sendFormRef = useRef();
  const snippetsPopupHolderRef = useRef();
  const refEdit = editorRef || defaultEditorRef;
  const fileMimeTypes = useSelector(getFileMimeTypes);
  const config = useSelector((state) => state.config);
  const accId = useSelector((state) => state.accounts.active);
  const checkPermissions = useSelector(checkUserPermissions);
  const [snippet, setSnippet] = useState({ show: false });
  const [personalScript, setPersonalScript] = useState();
  const [sendFormError, setSendFormError] = useState(false);
  const [query, setQuery] = useState('');
  const [smartChipPopup, displaySmartChipPopup] = useState(false);
  const [sendForm, setSendForm] = useState(value || DEFAULT_FORM_STATE);
  const formValue = { ...sendForm, quote: sendForm.quote || quote };
  const { handleSearchCached, handleUpdateCache, removeExpiredCache } =
    useCachedSnippets();

  const { UI } = useURLSettings();

  const getSnippetsAttachments = (actorId, onLoad) => {
    dispatch({
      type: GET_ACTOR_ATTACHMENTS.REQUEST,
      payload: { actorId },
      callback: (attachments) =>
        onLoad(
          attachments.map((attachment) => ({
            ...attachment,
            label: attachment.title,
            value: AppUtils.makeAppUrl(`/download/${attachment.fileName}`),
          }))
        ),
      errorCallback: () => onLoad([]),
    });
  };

  const showOption = useMemo(
    () => ({
      [ReactionOption.smiles]: UI.reactionOptions.smiles !== false,
      [ReactionOption.snippets]:
        useSnippets && UI.reactionOptions.snippets !== false,
      [ReactionOption.script]:
        checkPermissions([PERMISSIONS.SCRIPTS_MANAGEMENT]) &&
        form &&
        UI.reactionOptions.script !== false,
      [ReactionOption.files]:
        fileMimeTypes && UI.reactionOptions.files !== false,
      [ReactionOption.rating]: rating && UI.reactionOptions.rating !== false,
    }),
    [UI.reactionOptions, useSnippets, fileMimeTypes, form, rating]
  );

  const handleScroll = Utils.debounce(() => {
    if (!snippet.show) return;
    const formOutOfView = AppUtils.isOutOfView(
      formRef.current,
      contentRef.current
    );
    // Скрываем сниппеты, если форма ушла из обл. видимости
    if (formOutOfView) setSnippet({ show: false });
  }, 200);

  useEffect(() => {
    const scrollBox = contentRef.current;
    if (scrollBox) scrollBox.addEventListener('scroll', handleScroll, true);
    return () => {
      removeExpiredCache();
      if (scrollBox)
        scrollBox.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  // Handle backend forced placeholder setting
  useEffect(() => {
    const handleSetPlaceholder = ({ detail }) => {
      if (detail.actorId !== actorId) return;
      setSendForm((prevValue) => ({ ...prevValue, message: detail.text }));
    };

    document.addEventListener(
      `${WS_EXTERNAL_PACKET}:${EXTERNAL_PACKET_ACTION['reactions.placeholder']}`,
      handleSetPlaceholder
    );

    return () => {
      document.removeEventListener(
        `${WS_EXTERNAL_PACKET}:${EXTERNAL_PACKET_ACTION['reactions.placeholder']}`,
        handleSetPlaceholder
      );
    };
  }, []);

  useEffect(() => {
    if (!sign && sendForm.sign) {
      setSendForm({ ...sendForm, sign: false });
    }
  }, [sign]);

  useEffect(() => {
    if (!!reactionId && value.appId) {
      dispatch({
        type: GET_SCRIPT.REQUEST,
        payload: value.appId,
        callback: ({ ref, ...script }) =>
          setPersonalScript({ script, app: { title: script.title } }),
        errorCallback: () => {},
      });
    }
  }, [id]);

  useOutsideClick({
    ref: formRef,
    callback: onClickOutside,
  });

  const handleOnChange = ({ value: reactionValue, error }) => {
    const el = document.querySelector('div.searchChip');
    if (el) {
      setQuery(el.innerText);
    } else {
      setQuery('');
      displaySmartChipPopup(false);
    }
    if (reactionValue.message.length) {
      reactionValue.message = AppUtils.htmlToBbCode(reactionValue.message);
      const newMesLength = reactionValue.message.trim().length;
      const oldMesLength = sendForm.message.trim().length;
      if (!snippet.show && (!oldMesLength || newMesLength < oldMesLength))
        setSnippet({ show: true, mode: 'auto' });
    } else if (snippet.show && snippet.mode === 'auto') {
      setSnippet({ show: false });
    }
    if (sendForm.rating === 0 && reactionValue.rating !== 0) {
      reactionValue.sign = false;
    } else if (sendForm.sign === false && reactionValue.sign === true) {
      reactionValue.rating = 0;
    }
    if (reactionValue.quote === '' && onClearQuote) onClearQuote();
    if (!fileMimeTypes && reactionValue.files.length) {
      reactionValue.files = [];
    }
    setSendForm({ ...reactionValue });
    sendFormRef.current = reactionValue;
    setSendFormError(error);
    onChange({ value: reactionValue });
  };

  const handleOnSubmit = (submitProps) => {
    const valueCopy = { ...submitProps.value };
    valueCopy.message = AppUtils.htmlToBbCode(valueCopy.message);
    if (personalScript) {
      valueCopy.appId = personalScript.appId;
      valueCopy.appSettings = personalScript.appSettings;
    }
    setSnippet({ show: false });
    onSubmit({ id: submitProps.id, value: valueCopy });
    setSendForm({
      message: '',
      files: [],
      sign: false,
      rating: 0,
    });
    setPersonalScript(null);
  };

  // Удалить поле для создания смарт чипа
  const handleRemoveChipField = () => {
    const descrHtml = refEdit.current.innerHTML;
    const newDescr = descrHtml.replace(SEARCH_SMART_CHIP_REGEXP, '@$1');
    setSendForm({
      ...sendForm,
      message: newDescr,
    });
    displaySmartChipPopup(false);
  };

  // Добавить смарт чип для объекта
  const handleAddChip = ({ objType, id: objId, title }) => {
    const type = objType === 'graphFolder' ? 'graph' : objType;
    const bbCode = ` [${type}=${objId}]${Utils.stripHtml(title)}[/${type}] `;
    const descrHtml = refEdit.current.innerHTML;
    const newDescr = AppUtils.htmlToBbCode(descrHtml).replace(
      SEARCH_SMART_CHIP_REGEXP,
      bbCode
    );
    setSendForm({
      ...sendForm,
      message: newDescr,
    });
    displaySmartChipPopup(false);
  };

  const handleKeyEvent = () => {
    setTimeout(() => {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      displaySmartChipPopup(true);
      setQuery('');
      AppUtils.addChipField(selection, range);
      const descrHtml = refEdit.current.innerHTML;
      handleOnChange({ value: { ...sendFormRef.current, message: descrHtml } });
    }, 0);
  };

  const { open: openScriptModal } = useModal('PersonalScriptModal', {
    onSubmit: setPersonalScript,
  });

  const renderArtifacts = () => {
    return (
      <>
        {personalScript ? (
          <div>
            <ScriptChip
              script={personalScript}
              onEdit={openScriptModal}
              onClose={() => {
                setPersonalScript(null);
              }}
            />
          </div>
        ) : null}

        {getUrlAttachments(formValue.message, {
          youtube: {
            opts: {
              height: 100,
              width: 170,
            },
          },
        })}
      </>
    );
  };

  const renderSnippetsPopup = ({ onClick }) => {
    if (!refEdit.current || !snippet.show) return null;
    return ReactDOM.createPortal(
      <SnippetsPopup
        mode={snippet.mode}
        messageNode={refEdit.current}
        message={snippet.mode === 'auto' ? formValue.message : null}
        handleSelect={(text, snippetId) => {
          getSnippetsAttachments(snippetId, (snippetAttachments) => {
            setSnippet({ show: false });
            const files = unionBy(
              sendForm?.files || [],
              snippetAttachments,
              'id'
            );

            if (snippet.mode === 'auto') {
              formRef.current.click();
              refEdit.current.focus();
              handleOnChange({
                value: {
                  ...sendForm,
                  message: text,
                  files,
                },
              });
              setTimeout(() => Cursor.moveCursorToEnd(refEdit.current), 100);
            } else {
              setSendForm({
                ...sendForm,
                files,
              });
              onClick({ value: text });
            }
          });
        }}
        handleBlur={() => setSnippet({ show: false })}
        handleSearchCached={handleSearchCached}
        handleUpdateCache={handleUpdateCache}
      />,
      snippetsPopupHolderRef.current
    );
  };

  if (closed) return null;
  return (
    <div
      styleName={cn('form', { editMode: !!reactionId && !rating })}
      ref={formRef}
      onMouseDown={(e) => e.stopPropagation()}
      onTouchStart={(e) => e.stopPropagation()}
    >
      <div className={scss.snippetsPopupHolder} ref={snippetsPopupHolderRef} />
      {formLabel ? (
        <Label
          value={formLabel}
          styleName={cn('form__label', {
            focus: false,
            error: false,
          })}
        />
      ) : null}
      <ForwardSendForm
        id={id}
        ref={refEdit}
        type="chat"
        keyEnterAction={mode === REACTIONS_MODE.chat ? 'submit' : 'lineBreak'}
        value={{
          ...formValue,
          message: AppUtils.bbCodeToHtml(formValue.message, accId),
        }}
        placeholder={placeholder}
        sendButtonLabel={!buttonsUnderForm ? sendButtonLabel : null}
        cancelButtonLabel={!buttonsUnderForm ? cancelButtonLabel : null}
        options={{
          sign,
          reject,
          done,
          smiles: showOption[ReactionOption.smiles],
          snippets: showOption[ReactionOption.snippets],
          files: showOption[ReactionOption.files]
            ? { multiple: true, preview: true, accept: fileMimeTypes }
            : null,
          form: showOption[ReactionOption.script],
          rating: showOption[ReactionOption.rating],
        }}
        error={sendFormError}
        customControls={<CustomBar actorId={actorId} />}
        maxFileSize={config.maxFileSize || 25 * 1024 * 1024}
        contentType="html"
        autoFocus={autoFocus}
        artifacts={renderArtifacts()}
        onCancel={onCancel}
        onClickForm={(e, value) => openScriptModal(value)}
        onClickSnippets={() => setSnippet({ show: true, mode: 'manual' })}
        snippetsItems={renderSnippetsPopup}
        onChange={handleOnChange}
        onSubmit={handleOnSubmit}
        onKeyPress={(e) => {
          if (e.key === 'Escape') {
            handleRemoveChipField();
          } else if (e.key === '@') {
            handleKeyEvent();
          }
        }}
      />
      {smartChipPopup ? (
        <Popover
          opened
          topLevel={!window.frameElement}
          anchors={{
            binding: Popover.ANCHOR.left_top,
            content: Popover.ANCHOR.left_top,
          }}
          bindingElement={document.querySelector('div.searchChip')}
          content={
            <SmartChipPopup
              query={query}
              users={users}
              onSelect={handleAddChip}
              onClose={handleRemoveChipField}
            />
          }
        >
          <div />
        </Popover>
      ) : null}
      {buttonsUnderForm ? (
        <div styleName="form__btns">
          <Button
            label={cancelButtonLabel}
            size="smallplus"
            fontWeight="normal"
            type="text"
            onClick={onCancel}
          />
          <Button
            label={sendButtonLabel}
            size="smallplus"
            fontWeight="normal"
            visibility={
              sendForm.message ||
              (sendForm.files && sendForm.files.length) ||
              personalScript
                ? 'visible'
                : 'disabled'
            }
            onClick={() => handleOnSubmit({ id, value: { ...sendForm } })}
          />
        </div>
      ) : null}
    </div>
  );
}

ReactionForm.propTypes = {
  id: PropTypes.string.isRequired,
  actorId: PropTypes.string,
  reactionId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onClearQuote: PropTypes.func,
  onClickOutside: PropTypes.func,
  editorRef: PropTypes.object.isRequired,
  contentRef: PropTypes.object.isRequired,
  value: PropTypes.object,
  placeholder: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  sendButtonLabel: PropTypes.string,
  buttonsUnderForm: PropTypes.bool,
  users: PropTypes.array,
  sign: PropTypes.bool,
  reject: PropTypes.bool,
  done: PropTypes.bool,
  rating: PropTypes.bool,
  quote: PropTypes.string,
  form: PropTypes.bool,
  closed: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(REACTIONS_MODE)),
  formLabel: PropTypes.string,
  useSnippets: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

export default ReactionForm;
