import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  actorName: {
    id: 'actorName',
    defaultMessage: 'Actor name',
  },
  addImageOrColor: {
    id: 'addImageOrColor',
    defaultMessage: 'Add image or color',
  },
  actorAvatar: {
    id: 'actorAvatar',
    defaultMessage: 'Actor avatar',
  },
  refInfo: {
    id: 'refInfo',
    defaultMessage: 'A string value for making actors API requests by ref',
  },
  saUser: {
    id: 'saUser',
    defaultMessage: 'Single account user',
  },
});

export default { ...globalIntl, ...m };
