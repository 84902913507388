import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'mw-style-react';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import cn from 'classnames';
import './UsersAvatars.scss';

function UsersAvatars(props) {
  const {
    users = [],
    size = 'medium',
    limit = 5,
    offset = 20,
    onClick = () => {},
  } = props;
  const collapsedUsersCount = users.length - limit;
  const usersToShow = users.slice(0, limit);
  const avatarSize = size === 'medium' ? 32 : 28;
  let width =
    usersToShow.length * avatarSize +
    (usersToShow.length - 1) * (avatarSize / 2 - offset);
  if (collapsedUsersCount > 0) width += 16;

  const renderUsers = () =>
    usersToShow.map((user, index) => {
      const { userId, id, name, nick, avatar, color, icon, userType, type } =
        user;
      return (
        <div
          key={`${id || userId}_${index}`}
          styleName="users__avabox"
          style={{ left: -(index * offset) }}
          onClick={(e) => onClick(e, user)}
        >
          <Tooltip styleName="users__tooltip" value={name || nick}>
            <UserAvatar
              styleName={cn('users__avatar', size)}
              label={name || nick}
              size={size}
              src={avatar}
              bgColor={color}
              customIcon={icon ? { type: icon } : null}
              userType={userType || type}
            />
          </Tooltip>
        </div>
      );
    });

  const renderCounter = () => {
    const boxOffset = usersToShow.length;
    const label =
      collapsedUsersCount > 99 ? '+99' : `+${collapsedUsersCount.toString()}`;
    return (
      <div
        key="counter"
        styleName="users__avabox"
        style={{ left: -(boxOffset * offset) }}
      >
        <div key="counter" styleName={cn('users__counter', size)}>
          {label}
        </div>
      </div>
    );
  };

  return (
    <div styleName="users" style={{ width }}>
      {renderUsers()}
      {collapsedUsersCount > 0 ? renderCounter() : null}
    </div>
  );
}

UsersAvatars.propTypes = {
  users: PropTypes.array.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  offset: PropTypes.number,
  limit: PropTypes.number,
  onClick: PropTypes.func,
};

export default UsersAvatars;
