import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icon, Utils } from 'mw-style-react';
import {
  MIN_PANEL_WIDTH,
  MAX_PANEL_WIDTH,
} from '@control-front-end/common/constants/graphActors';
import './ResizablePanel.scss';

function ResizablePanel({ onChange, children }) {
  const resizer = useRef();
  const [isResizing, setIsResizing] = useState(false);

  const panelRef = useRef();

  const [width, setWidth] = useState(
    () => Utils.fromStorage('panelWidth') || MAX_PANEL_WIDTH
  );

  /**
   * Handler for changing the size of the panel
   */
  const handleResizePanel = (e) => {
    const rect = panelRef.current.getBoundingClientRect();
    const width = rect.width + rect.left - e.clientX;

    if (width < MIN_PANEL_WIDTH || width > MAX_PANEL_WIDTH) return;
    setWidth(width);

    Utils.toStorage('panelWidth', width);
    onChange?.(width);
  };

  const stopResize = () => {
    setIsResizing(false);
    document.removeEventListener('mousemove', handleResizePanel, false);
    document.removeEventListener('mouseup', stopResize, false);
  };

  useEffect(() => {
    const initResize = () => {
      setIsResizing(true);
      document.addEventListener('mousemove', handleResizePanel, false);
      document.addEventListener('mouseup', stopResize, false);
    };

    const resizerElement = resizer.current;

    resizerElement.addEventListener('mousedown', initResize, false);
    return () => {
      resizerElement?.removeEventListener('mousedown', initResize, false);
    };
  }, []);

  return (
    <div ref={panelRef} style={{ width: `${width}px` }} styleName="wrap">
      <div styleName={cn('panel', { resizing: isResizing })}>
        <div ref={resizer} styleName="panel__resizer">
          <Icon size="small" type="more" />
        </div>
        <div styleName="panel__content">{children}</div>
      </div>
    </div>
  );
}

ResizablePanel.propTypes = {
  onChange: PropTypes.func,
  children: PropTypes.node,
};

export default ResizablePanel;
