import mapValues from 'lodash/mapValues';

import { Utils } from 'mw-style-react';
import {
  ActorTab,
  ReactionOption,
} from '@control-front-end/common/constants/graphActors';
import {
  URL_PARAMS,
  MANAGE_UI_SPLITTER,
  MANAGE_UI_LIST_SPLITTER,
} from '@control-front-end/common/constants/urlParams';
import TOGGLING_UI_ELEMENT from '@control-front-end/common/constants/togglingUIElements';

const DEFAULT_FOCUS_MODE = 'false';

const URL_TO_BOOL = { true: true, false: false };

const SETTINGS_BY_FOCUS_MODE = {
  // If focusMode is true - then disable all elements
  true: {
    ...mapValues(TOGGLING_UI_ELEMENT, () => false),
    eventTabs: mapValues(ActorTab, () => false),
  },
  // If focusMode is false - then all elements are enabled
  false: {
    ...mapValues(TOGGLING_UI_ELEMENT, () => true),
    eventTabs: {},
  },
};

const getSettingsFromURL = (urlValue, config) => {
  if (urlValue[0] === 'true') {
    return {};
  }
  if (urlValue[0] === 'false') {
    return mapValues(config, () => false);
  }

  const customSettings = urlValue.reduce(
    (acc, elName) => ({ ...acc, [elName]: true }),
    {}
  );

  return {
    // Custom eventTabs go first to save object keys order (first tab of eventTabs param is a default active tab)
    ...customSettings,
    ...Utils.pickBy(
      mapValues(config, () => false),
      (v, tabName) => customSettings[tabName] === undefined
    ),
  };
};

const parseManageUI = (manageUIUrl) => {
  return mapValues(
    manageUIUrl.split(MANAGE_UI_SPLITTER).reduce((acc, paramSubstring) => {
      const [elName, ...value] = paramSubstring.split(MANAGE_UI_LIST_SPLITTER);
      acc[elName] = value;
      return acc;
    }, {}),
    (value, elName) => {
      // eventTabs can hold true/false value or an array of specific tabs to display
      if (elName === 'eventTabs' && value.length) {
        return getSettingsFromURL(value, ActorTab);
      }
      // reactionOptions is an array of options to display
      if (elName === 'reactionOptions' && value.length) {
        return getSettingsFromURL(value, ReactionOption);
      }
      // Other elements could be only enabled or disabled
      return URL_TO_BOOL[value[0]];
    }
  );
};

const settingsByUrlCache = {};

/**
 * Retrieves URL settings and processes them to generate UI settings.
 *
 * @return {object} The UI settings object
 */
export default function getURLSettings() {
  if (settingsByUrlCache[document.location.search]) {
    return settingsByUrlCache[document.location.search];
  }

  const queryParams = Utils.getQueryParam(document.location.search);
  const { focusMode, manageUI, ...rest } = Utils.pickBy(
    queryParams,
    (v, key) => URL_PARAMS[key]
  );

  const customUISettings = manageUI ? parseManageUI(manageUI) : {};

  const focuseModeUISettings =
    SETTINGS_BY_FOCUS_MODE[focusMode || DEFAULT_FOCUS_MODE];

  settingsByUrlCache[document.location.search] = {
    UI: {
      ...focuseModeUISettings,
      ...customUISettings,
      eventTabs: {
        // Custom eventTabs go first to save object keys order (first tab of eventTabs param is a default active tab)
        ...customUISettings.eventTabs,
        ...Utils.pickBy(
          focuseModeUISettings.eventTabs,
          (v, tabName) => customUISettings.eventTabs?.[tabName] === undefined
        ),
      },
      fallBackEventTab: focusMode || manageUI ? ActorTab.chat : null,
      reactionOptions: {
        ...customUISettings.reactionOptions,
      },
    },
    focusMode: URL_TO_BOOL[focusMode],
    ...rest,
  };

  return settingsByUrlCache[document.location.search];
}
