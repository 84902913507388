import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {
  Space,
  Label,
  Stack,
  Icon,
  Chip,
  Emoji,
  Popover,
  Tab,
  TabItem,
  Card,
  Upload,
  Tooltip,
  cr,
} from 'mw-style-react';

import SelectUsersAndGroups from '@control-front-end/common/components/SelectUsersAndGroups';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import AppUtils from '@control-front-end/utils/utils';
import { GET_STARRED_ACTORS } from '@control-front-end/common/constants/scripts';
import { GET_EMOJI_IMAGE, UPLOAD_FILE, ACCESS_RULES_ACTIONS } from 'constants';

import { useIntl } from 'hooks';

import scss from './CustomBarSettings.scss';
import mes from './intl';

const TABS = { emoji: 'emoji', image: 'image' };

const mapAndTransformUsers = (
  users,
  action = ACCESS_RULES_ACTIONS.existing
) => {
  return users.reduce((acc, user) => {
    acc.set(user.userId || user.id || user.saId, {
      ...user,
      privs: { view: true, modify: false, remove: false },
      action: action || user.action,
      userId: user.userId || user.id || user.saId,
      userType: user.type,
      name: user.name || user.nick,
    });
    return acc;
  }, new Map());
};

const calculateGroupsCount = (users) => {
  return (
    Array.from(users.values()).filter(
      (r) => r.userType === 'group' && r.action !== ACCESS_RULES_ACTIONS.delete
    ).length || 0
  );
};

const calculateActiveUsers = (users) => {
  return Array.from(users.values()).filter(
    (user) => user.action !== ACCESS_RULES_ACTIONS.delete
  );
};

function CustomBarSettings({ users, picture, onChange, ownerId, id }) {
  const [activeTab, setActiveTab] = useState(TABS.emoji);
  const [starredUsers, setStarredUsers] = useState([]);
  const pictureUrl = picture ? AppUtils.makeAppUrl(`/download/${picture}`) : '';
  const dispatch = useDispatch();
  const t = useIntl();
  const groupsCount = calculateGroupsCount(users);
  const activeUsers = calculateActiveUsers(users);

  useEffect(() => {
    if (id) {
      dispatch({
        type: GET_STARRED_ACTORS.REQUEST,
        payload: { actorId: id },
        callback: (users) => {
          const transformedUsers = mapAndTransformUsers(users);
          setStarredUsers(transformedUsers);
          onChange({
            users: transformedUsers,
          });
        },
      });
    }
  }, [id]);

  const handleSelectUsers = (newUsers) => {
    const transformedNewUsers = mapAndTransformUsers(
      [].concat(newUsers),
      ACCESS_RULES_ACTIONS.create
    );

    // Update existing users or add new users
    const updatedUsers = new Map(users);
    transformedNewUsers.forEach((newUser, userId) => {
      if (updatedUsers.has(userId)) {
        updatedUsers.set(userId, {
          ...updatedUsers.get(userId),
          action: starredUsers.has(userId)
            ? ACCESS_RULES_ACTIONS.existing
            : ACCESS_RULES_ACTIONS.create,
        });
      } else {
        updatedUsers.set(userId, newUser);
      }
    });

    onChange({
      users: updatedUsers,
    });
  };

  const handleDeleteUser = (userId) => {
    const updatedUsers = new Map(users);
    if (starredUsers.has(userId)) {
      updatedUsers.set(userId, {
        ...updatedUsers.get(userId),
        action: ACCESS_RULES_ACTIONS.delete,
      });
    } else {
      updatedUsers.delete(userId);
    }
    onChange({
      users: updatedUsers,
    });
  };

  const handleFileUpload = (files) => {
    dispatch({
      type: UPLOAD_FILE.REQUEST,
      payload: {
        files,
        callback: (attachments) => {
          const picture = attachments[0].fileName;
          onChange({ id: 'picture', value: picture });
        },
      },
    });
  };

  const handleEmodgiChange = (emoji) => {
    dispatch({
      type: GET_EMOJI_IMAGE.REQUEST,
      payload: {
        emoji,
        callback: (emojiFile) => {
          handleFileUpload([emojiFile]);
        },
      },
    });
  };

  return (
    <Space top bottom>
      <Stack>
        <Stack.H size={Stack.SIZE.small} alignItems="center">
          <Stack.H size={Stack.SIZE.xxsmall} alignItems="center">
            <Label fontWeight="semibold" value={t(mes.barIcon)} />
            <Tooltip className={scss.tooltip} value={t(mes.barIconTooltip)}>
              <Icon type="info" size="small" />
            </Tooltip>
          </Stack.H>
          <div onClick={(e) => e.stopPropagation()}>
            <Popover
              content={({ onClose }) => (
                <Card className={scss.barIconContent} fullWidth>
                  <Tab
                    value={activeTab}
                    underline={true}
                    onChange={({ value }) => setActiveTab(value)}
                    size="small"
                  >
                    <TabItem value={TABS.emoji}>
                      <Space size="xxsmall" top bottom>
                        <Stack.H alignItems="center" size="xxsmall">
                          <Icon type="smile" />
                          <Label value={t(mes.emojiTabName)} />
                        </Stack.H>
                      </Space>
                    </TabItem>
                    <TabItem value={TABS.image}>
                      <Space size="xxsmall" top bottom>
                        <Stack.H alignItems="center" size="xxsmall">
                          <Icon type="picture" />
                          <Label value={t(mes.imageTabName)} />
                        </Stack.H>
                      </Space>
                    </TabItem>
                  </Tab>
                  <Stack>
                    {cr(
                      [
                        activeTab === TABS.emoji,
                        () => (
                          <Emoji.Picker
                            onSelect={handleEmodgiChange}
                            onClose={onClose}
                            className={scss.emojiPickerTest}
                          />
                        ),
                      ],
                      [
                        activeTab === TABS.image,
                        () => (
                          <Space top bottom size="xsmall">
                            <Stack.H fullWidth justifyContent="center">
                              <Upload
                                className={[
                                  scss.pictureUpload,
                                  { [scss.loaded]: !!picture },
                                ]}
                                type="avatar"
                                accept="image/*"
                                onChange={({ value }) =>
                                  handleFileUpload(value)
                                }
                              >
                                <div
                                  className={cn(scss.scriptIcon, {
                                    [scss.empty]: !picture,
                                  })}
                                  style={{
                                    backgroundImage: `url(${pictureUrl})`,
                                  }}
                                />
                              </Upload>
                            </Stack.H>
                          </Space>
                        ),
                      ]
                    )}
                  </Stack>
                </Card>
              )}
              anchors={{
                binding: Popover.ANCHOR.left_bottom,
                content: Popover.ANCHOR.left_top,
              }}
              padding={4}
              topLevel
            >
              {({ onToggle }) => (
                <div
                  className={cn(scss.scriptIcon, {
                    [scss.empty]: !picture,
                  })}
                  onClick={onToggle}
                  style={{
                    backgroundImage: `url(${pictureUrl})`,
                  }}
                >
                  {picture ? (
                    <Icon
                      className={scss.editIcon}
                      type="edit"
                      size="small"
                      colorType="grey"
                    />
                  ) : (
                    <Icon type="plus" size="small" />
                  )}
                </div>
              )}
            </Popover>
          </div>
        </Stack.H>
        <Stack size={Stack.SIZE.small}>
          <Stack.H size={Stack.SIZE.xxsmall} alignItems="center">
            <Label fontWeight="semibold" value={t(mes.addUsers)} />
            <Tooltip className={scss.tooltip} value={t(mes.addUsersTooltip)}>
              <Icon type="info" size="small" />
            </Tooltip>
          </Stack.H>
          <SelectUsersAndGroups
            exclude={Array.from(users.values()).filter(
              (i) => i.action !== ACCESS_RULES_ACTIONS.delete
            )}
            showExcluded={true}
            handleSelect={handleSelectUsers}
            handleInvite={null}
            handleFocus={() => {}}
          />
        </Stack>
        <Stack fullWidth fullHeight size={Stack.SIZE.none}>
          <Space bottom>
            <Label
              fontSize="semibold"
              value={`${t(mes.groups)}: ${groupsCount}, ${t(mes.users)}: ${
                activeUsers.length - groupsCount
              }`}
            />
          </Space>
          {activeUsers && activeUsers?.length ? (
            <Stack fullWidth>
              {activeUsers.map(
                ({
                  avatar,
                  type,
                  name,
                  customIcon,
                  userId,
                  saId,
                  logins = [],
                }) => {
                  customIcon = type === 'group' ? 'persons' : '';
                  const id = userId || saId;
                  const isOwner = id === ownerId;
                  return (
                    <Stack.H
                      justifyContent="spaceBetween"
                      alignItems="center"
                      fullWidth
                      key={id}
                      className={scss.user}
                    >
                      <Stack.H alignItems="center">
                        <UserAvatar
                          className={scss.userAvatar}
                          size="medium"
                          src={avatar}
                          label={!customIcon ? name : null}
                          customIcon={
                            customIcon
                              ? {
                                  type: customIcon,
                                  colorType: 'primary',
                                  size: 'small',
                                }
                              : null
                          }
                          userType={type}
                        />
                        <Stack.V size="micro" fullWidth justifyContent="center">
                          <Stack.H size="micro" fullWidth alignItems="center">
                            <Label
                              color={Label.COLOR.black}
                              overflow="cut"
                              value={name}
                            />
                            {isOwner ? (
                              <Chip
                                className={scss.chip}
                                closeIcon={false}
                                unspaced
                                type="rectangular"
                                color="accent"
                                size="small"
                                fontSize="small"
                                fontWeight="normal"
                                label={isOwner ? t(mes.owner) : null}
                              />
                            ) : null}
                          </Stack.H>
                          <Label
                            fontSize="small"
                            color={Label.COLOR.gray}
                            overflow="cut"
                            value={
                              type === 'user'
                                ? AppUtils.getUserLogin(logins)
                                : id
                            }
                          />
                        </Stack.V>
                      </Stack.H>
                      <div
                        onClick={() => {
                          handleDeleteUser(id);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <Icon type="close" />
                      </div>
                    </Stack.H>
                  );
                }
              )}
            </Stack>
          ) : null}
        </Stack>
      </Stack>
    </Space>
  );
}

CustomBarSettings.propTypes = {
  users: PropTypes.instanceOf(Map).isRequired,
  picture: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  ownerId: PropTypes.string,
};

export default CustomBarSettings;
