import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Yup from 'yup';
import uniqBy from 'lodash/uniqBy';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Tab,
  TabItem,
  Button,
  ProgressBar,
  PopoverBoundary,
  withForm,
  cr,
} from 'mw-style-react';
import AccessRules from '@control-front-end/common/components/AccessRules';
import {
  CREATE_SCRIPT,
  UPDATE_SCRIPT_ENV,
  UPDATE_SCRIPT_BAR_SETTINGS,
} from '@control-front-end/common/constants/scripts';
import { UPDATE_ACTOR } from '@control-front-end/common/constants/graphActors';
import { DEL_MODAL } from 'constants';
import { RE_APP_SHORT_NAME } from '@control-front-end/common/constants/regExp';
import useIntl from 'useIntl';
import mes from './intl';
import ScriptSettings from './components/ScriptSettings';
import CustomBarSettings from './components/CustomBarSettings';
// eslint-disable-next-line no-unused-vars
import m from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import l from './CreateScriptApp.scss'; // NOSONAR

const TABS = {
  settings: 'settings',
  access: 'access',
  customBarSettings: 'customBarSettings',
};

function CreateScriptAppBase(props) {
  const {
    data = {},
    values,
    handleSubmit,
    handleOnChange,
    onContentChange,
    visibility,
    onClose,
    isSubmit,
    errors,
  } = props;
  const t = useIntl();
  const [activeTab, setActiveTab] = useState(TABS.settings);

  const { boundaryRef } = useContext(PopoverBoundary.Context);

  return (
    <Modal
      styleName="m.modal l.modal"
      size="xlarge"
      visibility={visibility}
      onClose={onClose}
      label={data.id ? t(mes.editScriptApp) : t(mes.createScriptApp)}
    >
      <ModalContent
        enablePopoverBounding={false}
        styleName="m.modal__content l.modal__content"
      >
        <div styleName="l.content__wrap">
          <Tab
            value={activeTab}
            type="auto"
            underline={true}
            onChange={({ value }) => setActiveTab(value)}
          >
            <TabItem label={t(mes.settings)} value={TABS.settings} />
            <TabItem
              label={t(mes.scriptAccess)}
              value={TABS.access}
              visibility={data.id ? 'hidden' : 'visible'}
            />
            <TabItem
              label={t(mes.customBarSettings)}
              value={TABS.customBarSettings}
            />
          </Tab>
          <div ref={boundaryRef} styleName="l.content">
            {cr(
              [
                activeTab === TABS.settings,
                () => (
                  <ScriptSettings
                    values={values}
                    errors={errors}
                    handleOnChange={(scriptData) => {
                      handleOnChange(scriptData);
                      onContentChange();
                    }}
                  />
                ),
              ],
              [
                activeTab === TABS.access,
                () => (
                  <AccessRules
                    styleType="embeded"
                    rules={values.access}
                    ownerId={data.ownerId}
                    onChange={(access) =>
                      handleOnChange({ id: TABS.access, value: access })
                    }
                  />
                ),
              ],
              [
                activeTab === TABS.customBarSettings,
                () => (
                  <CustomBarSettings
                    picture={values.picture}
                    users={values.customBarSettings.users}
                    id={data.id}
                    ownerId={data.ownerId}
                    onChange={({ id, value, ...rest }) => {
                      handleOnChange({
                        id: id || TABS.customBarSettings,
                        value: value || rest,
                      });
                      onContentChange();
                    }}
                  />
                ),
              ]
            )}
          </div>
        </div>
      </ModalContent>
      <ModalButtons styleName="m.modal__buttons">
        <Button
          label={data.id ? t(mes.save) : t(mes.create)}
          size="large"
          fontWeight="normal"
          onClick={handleSubmit}
          visibility={isSubmit ? 'disabled' : 'visible'}
        />
        <ProgressBar
          styleName="m.modal__loader"
          type="circle"
          size="small"
          visibility={isSubmit ? 'visible' : 'hidden'}
        />
      </ModalButtons>
    </Modal>
  );
}

CreateScriptAppBase.propTypes = {
  data: PropTypes.object,
  visibility: PropTypes.bool,
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleOnChange: PropTypes.func,
  onContentChange: PropTypes.func,
  isSubmit: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
};

const CreateScriptApp = withForm(
  {
    mapPropsToValues(props) {
      const defaultSharedWith = [
        { value: 'userList', title: 'Users from the list' },
      ];
      if (!props.data || !props.data.id)
        return {
          sharedWith: defaultSharedWith,
          access: props.data ? props.data.access : [],
          customBarSettings: { users: new Map() },
        };
      const { title, ref, description, data, picture, envs, access } =
        props.data;
      const develop = envs?.find((i) => i.title === 'develop') || {};
      const production = envs?.find((i) => i.title === 'production') || {};
      const {
        apiLogin,
        apiSecret,
        companyId,
        procId: devProcId,
      } = develop.corezoidCredentials || {};
      const { procId: prodProcId } = production.corezoidCredentials || {};
      return {
        title,
        ref,
        description,
        sharedWith: data.sharedWith,
        picture,
        access,
        apiLogin,
        apiSecret,
        companyId,
        devProcId,
        prodProcId,
        customBarSettings: { users: new Map() },
      };
    },
    yup: Yup.object().shape({
      title: Yup.string().min(2).required('field_required'),
      ref: Yup.string()
        .matches(new RegExp(RE_APP_SHORT_NAME), 'wrong_format')
        .required('field_required'),
      description: Yup.string().nullable(true),
      sharedWith: Yup.string(),
      picture: Yup.string().nullable(true),
      apiLogin: Yup.string().required('field_required'),
      apiSecret: Yup.string().required('field_required'),
      companyId: Yup.string().required('field_required'),
      devProcId: Yup.string().required('field_required'),
      prodProcId: Yup.string().required('field_required'),
    }),
    handleSubmit: (values, dispatch, formActions, props) => {
      const { data } = props;
      formActions.callback = props.callback;
      const {
        title,
        ref,
        description,
        sharedWith,
        picture,
        access,
        apiLogin,
        apiSecret,
        companyId,
        devProcId,
        prodProcId,
        customBarSettings,
      } = values;
      const credentials = { apiLogin, apiSecret, companyId };
      const corezoidCredentials = {
        develop: { ...credentials, procId: devProcId },
        production: { ...credentials, procId: prodProcId },
      };
      if (data.id) {
        dispatch({
          type: UPDATE_ACTOR.REQUEST,
          payload: {
            id: data.id,
            title,
            ref,
            description,
            picture,
            formId: data.formId,
            formData: { sharedWith },
            access,
          },
          callback: (newData) => {
            const envActions = [];
            data.envs.forEach((env) => {
              const envIsChanged = Object.keys(
                corezoidCredentials[env.title]
              ).some(
                (key) =>
                  corezoidCredentials[env.title][key] !==
                  env.corezoidCredentials[key]
              );
              if (envIsChanged) {
                envActions.push({
                  scriptId: data.id,
                  envId: env.id,
                  credentials: corezoidCredentials[env.title],
                });
              }
            });
            if (envActions.length) {
              const copyEnvs = structuredClone(data.envs);
              formActions.onClose = props.onClose;
              dispatch({
                type: UPDATE_SCRIPT_ENV.REQUEST,
                payload: { envs: envActions },
                formActions,
                callback: (updatedEnvs) => {
                  copyEnvs.forEach((env, index) => {
                    const updatedData = updatedEnvs.find(
                      (i) => i.id === env.id
                    );
                    if (updatedData) copyEnvs.splice(index, 1, updatedData);
                  });
                  props.callback?.({ ...newData, envs: copyEnvs });
                },
              });
            } else {
              props.callback?.({ ...newData });
            }

            dispatch({
              type: UPDATE_SCRIPT_BAR_SETTINGS.REQUEST,
              payload: {
                access,
                customBarUsers: customBarSettings?.users,
                id: data.id,
              },
            });
            dispatch({ type: DEL_MODAL });
          },
        });
      } else {
        dispatch({
          type: CREATE_SCRIPT.REQUEST,
          payload: {
            title,
            ref,
            description,
            sharedWith,
            picture,
            corezoidCredentials,
            customBarUsers: Array.from(customBarSettings.users.values()),
            access: uniqBy(
              [...access, ...customBarSettings.users],
              (user) => user.userId || user.saId
            ),
          },
          formActions,
          callback: () => {
            dispatch({ type: DEL_MODAL });
          },
        });
      }
    },
  },
  CreateScriptAppBase
);

export default CreateScriptApp;
