import {
  call,
  put,
  takeEvery,
  select,
  delay,
  actionChannel,
  take,
} from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import { NOTIFY_LEVEL, RequestStatus, SHOW_NOTIFY } from 'constants';
import {
  GET_REACTIONS,
  CREATE_REACTION,
  UPDATE_REACTION,
  DELETE_REACTION,
  MARK_AS_READ_REACTION,
  SET_REACTIONS_REQ_STATUS,
  REACTIONS_REQ_LIMIT,
  WS_CREATE_REACTION,
  WS_UPDATE_REACTION,
  WS_DELETE_REACTION,
  CLEAR_REACTIONS,
  WS_SYNC_REACTIONS,
  WS_CDU_CHANGES,
} from '@control-front-end/common/constants/reactions';
import {
  UPDATE_ACTOR,
  UPDATE_ACTOR_LIST,
  UPDATE_ACTOR_VIEW,
  WS_CREATE_ATTACHMENTS,
  WS_DELETE_ATTACHMENTS,
} from '@control-front-end/common/constants/graphActors';
import api from '@control-front-end/common/sagas/api';
import {
  makeAttach,
  bindAttach,
  unBindAttach,
} from '@control-front-end/common/sagas/attachUtils';
import AppUtils from '@control-front-end/utils/utils';
import { makeUserWithAvatar, updateActorProp } from './graph/graphHelpers';
import { getActiveLayer } from '../routes/ActorsGraph/sagas/layers/layerHelpers';
import { updateActorView } from './actorView';

// Формируем массив индексов реакций одинакового типа
function makeGroupReactionsIndexes(list, groupTypes) {
  const groupList = [];
  let obj = null;
  const listLen = list.length;
  const commit = () => {
    if (!obj) return;
    groupList.push(obj);
    obj = null;
  };
  list.forEach((i, index) => {
    const type = i.data.type;
    const hasChildReactions = i.children && i.children.length;
    if (!groupTypes.includes(type) || hasChildReactions) {
      commit();
      groupList.push({ start: index, end: index + 1, type });
      return;
    }
    if (!obj) {
      obj = { start: index, end: index + 1, type };
    } else if (obj.type !== type || hasChildReactions) {
      commit();
    } else if (!hasChildReactions) {
      obj.end = index + 1;
    }
    if (listLen - 1 === index) commit();
  });
  return groupList;
}

// Добавляем группирующую реакцию
function makeReactionGroups(list) {
  const groups = makeGroupReactionsIndexes(list, ['view']);
  const newList = [];
  for (const g of groups) {
    const viewsR = list.slice(g.start, g.end);
    if (viewsR.length < 2) {
      newList.push(...viewsR);
      continue;
    }
    viewsR.forEach((i) => {
      i.groupedItem = true;
    });
    const groupReaction = {
      id: AppUtils.udid(),
      isGroup: true,
      title: g.type,
      children: viewsR,
      treeInfo: {},
    };
    newList.push(groupReaction);
  }
  return newList;
}

/**
 * Marks reactions list with "listIndex"
 * "listIndex" - is used to detect reaction position inside the whole
 * reactions list, including reactions that havn't been loaded yet
 *
 * @param {Array} list - list of reactions
 * @param {Number} batchOffset - offset for loaded batch of reactions
 */
const markWithListIndexes = (list, batchOffset = 0) => {
  list.forEach((item) => {
    if (item.isGroup) {
      item.listIndex = {
        first: batchOffset,
        last: batchOffset + item.children.length - 1,
      };
      batchOffset += item.children.length;
    } else {
      item.listIndex = batchOffset;
      batchOffset += 1;
    }
  });
  return list;
};

/**
 * Defines indexes edges for reactions that have been loaded
 * among of all reactions in the list
 *
 * @param {Array} list - list of reactions
 */
const getIndexesEdges = (list) => {
  if (!list.length) {
    return {
      first: null,
      last: null,
    };
  }
  const firstItem = list[0];
  const lastItem = list[list.length - 1];

  // For the groupped reactions (for example - views) - get the first and last index of the group
  return {
    first: firstItem.isGroup ? firstItem.listIndex.first : firstItem.listIndex,
    last: lastItem.isGroup ? lastItem.listIndex.last : lastItem.listIndex,
  };
};

/**
 * Helps with setting up of "endReached" and "startReached" state fields
 * based on new reactions list and total amount when this values have been changed
 *
 * @param {Object} list - new list of reactions
 * @param {Number} total - total amount of reactions
 */
const getNewReactionsState = ({ list, total, ...rest }) => {
  const newList = markWithListIndexes(list);
  const { first, last } = getIndexesEdges(newList);

  return {
    ...rest,
    list: newList,
    total,
    endReached: last === total - 1,
    startReached: first === 0,
  };
};

// Получить плоский массив реакций с полной моделью
function* flatList(list) {
  const roots = [];
  const config = yield select((state) => state.config);
  for (let i = 0, l = list.length; i < l; i += 1) {
    const reaction = list[i];
    reaction.children = reaction.children || [];
    reaction.user = reaction.user || {};
    reaction.user = makeUserWithAvatar(reaction.user, config);
    roots.push(reaction);
  }
  return roots;
}

// Преобразовать список реакций в дерево
function* unflattenList(list) {
  const mapIndex = {};
  const fullMap = {};
  const roots = [];
  const config = yield select((state) => state.config);
  for (let i = 0, l = list.length; i < l; i += 1) {
    const reaction = list[i];
    mapIndex[reaction.id] = i;
    fullMap[reaction.id] = reaction;
    reaction.children = reaction.children || [];
    if (reaction.user) {
      reaction.user = makeUserWithAvatar(reaction.user, config);
    }
  }
  for (let i = 0, l = list.length; i < l; i += 1) {
    const reaction = list[i];
    if (reaction.treeInfo.parentId) {
      const parentReaction = fullMap[reaction.treeInfo.parentId];
      reaction.parentUserName = parentReaction.user.nick;
      list[mapIndex[reaction.treeInfo.parentId]].children.push(reaction);
    } else {
      roots.push(reaction);
    }
  }
  return makeReactionGroups(roots);
}

// Установить комментарий в нужное место в дереве
function setChildReaction(rootReaction, data) {
  if (rootReaction.id === data.treeInfo.parentId) {
    data.parentUserName = rootReaction.user.nick;
    rootReaction.children.push(data);
    return;
  }
  for (const child of rootReaction.children) {
    if (child.id === data.parentId) {
      data.parentUserName = child.user.nick;
      child.children.push(data);
      break;
    } else {
      setChildReaction(child, data);
    }
  }
}

// Установить дочерний комментарий сгруппированной реакции
function setChildToGroupedReaction(list, r) {
  const groupIndex = list.findIndex(
    (i) => i.isGroup && i.children.find((gId) => gId.id === r.treeInfo.branchId)
  );
  if (groupIndex === -1) return;
  const group = list[groupIndex];
  const rootReactionIndex = group.children.findIndex(
    (gId) => gId.id === r.treeInfo.branchId
  );
  if (rootReactionIndex === -1) return;
  const rootReaction = group.children[rootReactionIndex];
  setChildReaction(rootReaction, r);
  // выносим реакцию из группы в список
  list.splice(groupIndex + 1, 0, rootReaction);
  group.children.splice(rootReactionIndex, 1);
}

// Обновить реакцию в дереве
function updateChildReaction(rootReaction, data) {
  for (const child of rootReaction.children) {
    if (child.id === data.id) {
      child.description = data.description;
      child.extra = data.extra;
      child.attachments = data.attachments;
      child.updatedAt = data.updated_at;
      break;
    } else {
      updateChildReaction(child, data);
    }
  }
}

/** Check if we can add new reaction to the list of already loaded reactions */
function* canAddToTheStream(r) {
  const { startReached, endReached, orderValue, list } = yield select(
    (state) => state.reactions
  );
  const { treeInfo } = r;
  const userHaveLoadedLatestReactions =
    orderValue === 'ASC' ? endReached : startReached;

  return (
    userHaveLoadedLatestReactions || list.length === 0 || treeInfo.parentId
  );
}

// Удалить реакцию из дерева
function deleteChildReaction(rootReaction, data) {
  if (rootReaction.id === data.parentId) {
    const index = rootReaction.children.findIndex((i) => i.id === data.id);
    rootReaction.children.splice(index, 1);
    return;
  }
  for (const child of rootReaction.children) {
    if (child.id === data.parentId) {
      const index = child.children.findIndex((i) => i.id === data.id);
      child.children.splice(index, 1);
      break;
    } else {
      deleteChildReaction(child, data);
    }
  }
}

// Нотификация, что реакция добавлена
function* showNotifyAdded() {
  const reactions = yield select((state) => state.reactions);
  yield put({
    type: SHOW_NOTIFY.REQUEST,
    payload: {
      id: AppUtils.createRid(),
      type: NOTIFY_LEVEL.SUCCESS,
      label: 'Comment has been added to the end of the list',
    },
  });
  yield put({
    type: CREATE_REACTION.SUCCESS,
    payload: { total: reactions.total + 1 },
  });
}

export function makeUpdatedReactionStats(reactionsStats, action, reaction) {
  const {
    userId,
    data: { type },
  } = reaction;
  const newStats = reactionsStats ? structuredClone(reactionsStats) : {};
  if (action === 'add') {
    newStats[userId] = {
      ...newStats[userId],
      [type]:
        newStats[userId] && newStats[userId][type]
          ? newStats[userId][type] + 1
          : 1,
    };
  } else if (newStats[userId] && newStats[userId][type]) {
    newStats[userId][type] -= 1;
  }
  return newStats;
}

// Обновить счетчик комментариев для зоны на слое
function* updateLayerAreaCommentsCount(reaction, action) {
  const reactions = yield select((state) => state.reactions);
  const activeLayer = yield getActiveLayer();
  if (!activeLayer || reactions.layerId !== activeLayer.id) return;
  const { treeInfo, data } = reaction;
  if (data.type !== 'comment') return;
  const actorId = treeInfo.rootActorId;
  const findActor = activeLayer.nodes.find(
    (i) => i.data.actorId === actorId && i.isLayerArea
  );
  if (!findActor) return;
  const prevCount = findActor.data.commentsCount || 0;
  const newCount = action === 'add' ? prevCount + 1 : prevCount - 1;
  yield updateActorProp({
    type: UPDATE_ACTOR.SUCCESS,
    layerId: reactions.layerId,
    actorId,
    propId: 'commentsCount',
    value: newCount,
  });
}

// Актуализировать статистику реакций по актору
function* updateActorViewReactionStat(reaction, action) {
  const actorView = yield select((state) => state.actorView);
  const newStats = makeUpdatedReactionStats(
    actorView.reactionsStats,
    action,
    reaction
  );
  const newActorView = {};
  if (reaction.data.type === 'freeze') {
    newActorView.freeze = true;
    newActorView.readonly = true;
  } else if (reaction.data.type === 'sign' || reaction.data.type === 'done') {
    let isReadonly = false;
    for (const user of Object.keys(newStats)) {
      isReadonly = !!newStats[user].sign || !!newStats[user].done;
      if (isReadonly) break;
    }
    newActorView.readonly = isReadonly;
  } else if (reaction.data.type === 'reject' && action === 'delete') {
    newActorView.readonly = false;
  } else if (reaction.data.type === 'reject' && action === 'add') {
    newActorView.readonly = true;
  }
  yield put({
    type: UPDATE_ACTOR_VIEW.SUCCESS,
    payload: { ...actorView, ...newActorView, reactionsStats: newStats },
  });
}

// Обновить последнюю реакцию события в списке
function* updateEventsLastReaction(reaction) {
  const { list } = yield select((state) => state.actorsList);
  const copyList = list.slice();
  const index = copyList.findIndex(
    (i) => i.lastReaction && i.lastReaction.id === reaction.id
  );
  if (index === -1) return;
  const updatedEvent = { ...copyList[index], lastReaction: reaction };
  copyList.splice(index, 1, updatedEvent);
  yield put({
    type: UPDATE_ACTOR_LIST.SUCCESS,
    payload: {
      list: copyList,
    },
  });
}

// Добавить реакцию
function* addReaction(r) {
  const reactions = yield select((state) => state.reactions);
  const list = structuredClone(reactions.list);
  const addFunc = reactions.orderValue === 'ASC' ? 'push' : 'unshift';
  if (!r.treeInfo.parentId || reactions.view === 'flat') {
    list[addFunc]({ ...r });
  } else {
    const rootReaction = list.find((i) => i.id === r.treeInfo.branchId);
    if (rootReaction) {
      setChildReaction(rootReaction, r);
    } else {
      setChildToGroupedReaction(list, r);
    }
  }

  yield put({
    type: CREATE_REACTION.SUCCESS,
    payload: getNewReactionsState({
      list,
      total: reactions.total + 1,
      lastReaction: r,
    }),
  });
  yield updateActorViewReactionStat(r, 'add');
}

// Создать модель реакции
function* createReactionModel(r, attachments = []) {
  const config = yield select((state) => state.config);
  r.children = [];
  r.user = r.user || {};
  r.user = makeUserWithAvatar(r.user, config);
  r.attachments = attachments;
  if (yield canAddToTheStream(r)) {
    yield addReaction(r);
  } else {
    yield showNotifyAdded();
  }
}

// Удалить модель реакции
function* removeReactionModel(reaction) {
  const copyReaction = { ...reaction };
  const { id, treeInfo = {} } = copyReaction;
  const { parentId, branchId } = treeInfo;
  const reactions = yield select((state) => state.reactions);
  const reactionsList = structuredClone(reactions.list);
  let reactionExtendedObj;
  if (!parentId || reactions.view === 'flat') {
    const index = reactionsList.findIndex((i) => i.id === id);
    if (index === -1) return;
    reactionExtendedObj = reactionsList[index];
    reactionsList.splice(index, 1);
  } else {
    const rootReaction = reactionsList.find((i) => i.id === branchId);
    deleteChildReaction(rootReaction, { id, parentId });
  }
  const remainingReactions = reactionsList.filter(
    (i) => i.treeInfo.branchId !== id && i.treeInfo.parentId !== id
  );
  const deletedReactionsCount =
    reactionsList.length - remainingReactions.length + 1;
  yield put({
    type: DELETE_REACTION.SUCCESS,
    payload: getNewReactionsState({
      list: remainingReactions,
      total:
        reactions.total - deletedReactionsCount < 0
          ? 0
          : reactions.total - deletedReactionsCount,
    }),
  });
  if (reactionExtendedObj) {
    yield updateActorViewReactionStat(reactionExtendedObj, 'delete');
  }
}

// Обновить модель реакции
function* updateReactionModel(originReaction, attachments) {
  const reaction = structuredClone(originReaction);
  const config = yield select((state) => state.config);
  const reactions = yield select((state) => state.reactions);
  const reactionsList = structuredClone(reactions.list);
  const { id, treeInfo } = reaction;
  const { parentId, branchId } = treeInfo;
  reaction.user = reaction.user || {};
  reaction.user = makeUserWithAvatar(reaction.user, config);
  if (attachments) reaction.attachments = attachments;
  if (!parentId || reactions.view === 'flat') {
    const index = reactionsList.findIndex((i) => i.id === id);
    if (index !== -1) {
      const findReaction = reactionsList[index];
      reactionsList.splice(index, 1, { ...findReaction, ...reaction });
    }
  } else {
    const rootReaction = reactionsList.find((i) => i.id === branchId);
    updateChildReaction(rootReaction, reaction);
  }
  yield put({
    type: UPDATE_REACTION.SUCCESS,
    payload: { list: reactionsList },
  });
  yield updateEventsLastReaction(reaction);
}

// Проверить надо ли обрабатывать пакет из WS
function* isHandleWSPacket(reaction, tabId, checkIsModelExist = true) {
  const accounts = yield select((state) => state.accounts);
  const reactions = yield select((state) => state.reactions);
  const actorView = yield select((state) => state.actorView);
  const appInit = yield select((state) => state.appInit);
  const { id, accId, treeInfo, data } = reaction;
  if (tabId && appInit.tabId === tabId) {
    if (data.type === 'view') {
      yield delay(1000);
      yield updateActorViewReactionStat(reaction, 'add');
    }
    return false;
  }
  if (
    accounts.active !== accId ||
    (!reactions.layerId && actorView.id !== treeInfo.rootActorId) ||
    treeInfo.rootActorId !== reactions.rootActorId
  )
    return false;
  // Поиск существующей реакции
  if (checkIsModelExist) {
    const reactionFromState = AppUtils.recursiveFind(reactions.list, id);
    if (reactionFromState) return false;
  }
  return true;
}

/**
 * Получить реакции
 */
function* getReactions({
  payload: {
    actorId,
    rid,
    loadPrev,
    view,
    layerId,
    limit: customLimit,
    offset: offsetProp,
  },
  callback,
}) {
  const { reactions: prevState } = yield select((state) => state);

  const restartPagination = actorId !== prevState.rootActorId; // If actor is changed - then restart reactions loading

  const state = restartPagination
    ? {
        ...prevState,
        list: [],
        total: 0,
        reqStatus: 'success',
        lastReaction: null,
        rootActorId: null,
        startReached: false,
        endReached: false,
      }
    : prevState;

  const canLoadMore = loadPrev ? !state.startReached : !state.endReached;

  if (state.reqStatus !== RequestStatus.SUCCESS || !canLoadMore) {
    return;
  }

  let limit = customLimit || state.limit;

  // Setup offset value for the next batch to load
  let offset = 0;
  if (offsetProp !== undefined) {
    offset = offsetProp;
  } else if (state.list.length) {
    const { first, last } = getIndexesEdges(state.list);
    offset = loadPrev ? first - limit : last + 1;
  }

  // If we go under zero - then correct limit and offset for the next target batch
  if (offset < 0) {
    limit += offset;
    offset = 0;
  }

  yield put({
    type: SET_REACTIONS_REQ_STATUS,
    payload: { reqStatus: 'inProgress', rootActorId: actorId },
  });
  const reqTime = new Date().getTime();

  const { result, data } = yield call(api, {
    method: 'get',
    url: `/reactions/list/${actorId}`,
    queryParams: {
      limit,
      offset,
      rid,
      orderValue: state.orderValue,
      view,
    },
  });

  yield put({
    type: SET_REACTIONS_REQ_STATUS,
    payload: { reqStatus: 'success', rootActorId: actorId },
  });

  if (result !== RequestStatus.SUCCESS) {
    if (rid) {
      yield getReactions({
        payload: { actorId, loadPrev, view },
        callback,
      });
    }
    return;
  }

  const newBatch = yield call(
    view === 'flat' ? flatList : unflattenList,
    data.data.list
  );

  const newBatchWithIndexes = markWithListIndexes(newBatch, data.data.offset);

  let lastReaction = state.lastReaction;
  if (!state.list.length && !lastReaction && state.orderValue === 'DESC') {
    lastReaction = newBatch[0] || null;
  }

  const newList = loadPrev
    ? newBatchWithIndexes.concat(state.list)
    : state.list.concat(newBatchWithIndexes);

  const { first, last } = getIndexesEdges(newList);

  /**
   * There is cases when we can't check the end by the total amount because of the internal reactions replies
   * In other words - total will include not only root level reactions but also replies
   */
  const emptySearchResult = !loadPrev && data.data.list.length < limit;

  const newState = {
    list: newList,

    offset,
    reqTime,
    rootActorId: actorId,
    lastReaction,
    layerId,
    view,
    total: data.data.total,
    endReached: last === data.data.total - 1 || emptySearchResult,
    startReached: first === 0,
  };

  yield put({ type: GET_REACTIONS.SUCCESS, payload: newState });

  if (callback) callback(newState.list);
}

function* updateRootActorAttachments({
  rootActorId,
  filesToAdd = [],
  filesToDel = [],
}) {
  if (!filesToAdd.length && !filesToDel.length) return;
  const actorView = yield select((state) => state.actorView);
  const { id: actorViewId, reactionsAttachments } = actorView;
  if (rootActorId !== actorViewId) return;
  const oldAttachments = reactionsAttachments?.slice() || [];
  const newAttachments = oldAttachments.filter(
    (i) => !filesToDel.find((attachToDel) => attachToDel.id === i.id)
  );
  const newFilesWithoutDoubles = filesToAdd.filter(
    (i) => !oldAttachments.find((oldAttach) => oldAttach.id === i.id)
  );
  newAttachments.unshift(...newFilesWithoutDoubles);
  yield call(updateActorView, {
    payload: {
      actorData: { ...actorView, reactionsAttachments: newAttachments },
    },
  });
}

/**
 * Создать реакцию
 */
function* createReaction({ payload, callback }) {
  const {
    rootActorId,
    description,
    files,
    formActions,
    type,
    parentId,
    appId,
    appSettings,
    data: formData,
  } = payload;
  const { view } = yield select((state) => state.reactions);
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/reactions/${type}/${rootActorId}`,
    queryParams: { view },
    body: {
      description,
      parentId,
      appId,
      appSettings,
      data: formData,
    },
  });
  if (formActions) formActions.setSubmitting(false);
  if (result !== RequestStatus.SUCCESS) return;
  // создание аттачей
  const attachments = yield call(makeAttach, files, true);
  // Создать модель реакции
  yield call(createReactionModel, data.data, attachments);
  // привязка аттачей
  yield call(bindAttach, attachments, data.data.id);
  if (formActions) formActions.onClose();
  if (callback) callback(data.data);
}

/**
 * Обновить реакцию
 */
function* updateReaction({ payload }) {
  const {
    rootActorId,
    actorId,
    description,
    formActions,
    appId,
    appSettings,
    data: formData,
  } = payload;
  let { files } = payload;
  const reactions = yield select((state) => state.reactions);
  const reactionFromState = AppUtils.recursiveFind(reactions.list, actorId);
  // поиск аттачей для удаления
  const filesToDelete = [];
  for (const attach of reactionFromState.attachments) {
    if (!files.find((f) => f.id === attach.id)) {
      filesToDelete.push({ id: attach.id });
    }
  }
  // удаление аттачей
  if (filesToDelete.length) {
    yield call(unBindAttach, filesToDelete, reactionFromState.id);
    files = files.filter((i) => !filesToDelete.find((u) => u.id === i.id));
  }
  // создание аттачей
  const attachments = yield call(makeAttach, files, true);
  // привязка аттачей
  yield call(bindAttach, attachments, reactionFromState.id);
  const { result, data } = yield call(api, {
    method: 'put',
    url: `/reactions/${rootActorId}`,
    body: {
      actorId,
      description,
      appId: appId || reactionFromState.appId,
      appSettings: appSettings || reactionFromState.appSettings,
      data: formData,
    },
  });
  if (formActions) formActions.setSubmitting(false);
  if (result !== RequestStatus.SUCCESS) return;
  // Обновить модель реакции
  yield call(updateReactionModel, data.data, attachments);
  yield call(updateRootActorAttachments, {
    rootActorId,
    filesToAdd: attachments,
    filesToDel: filesToDelete,
  });
  if (formActions) formActions.onClose();
}

/**
 * Удалить реакцию
 */
function* deleteReaction({ payload }) {
  const { rootActorId, id, attachments } = payload;
  const { result } = yield call(api, {
    method: 'delete',
    url: `/reactions/${rootActorId}`,
    body: { actorId: id },
  });
  if (result !== RequestStatus.SUCCESS) return;
  // Удалить реакцию
  yield call(removeReactionModel, { ...payload });
  yield call(updateRootActorAttachments, {
    rootActorId,
    filesToDel: attachments,
  });
}

function* updateUnreadsCounter({ reaction, decrement = false }) {
  const actorView = yield select((state) => state.actorView);
  const auth = yield select((state) => state.auth);

  let unreadReactionRemoved = false;

  if (decrement) {
    const { list } = yield select((state) => state.reactions);
    const targetReaction = list.find(({ id }) => id === reaction.id);
    unreadReactionRemoved =
      targetReaction && targetReaction.listIndex < actorView.unreadReactions;
  }

  /**
   * Needed for case when user receives reaction via group assess but hasn't personal access
   * (Because "unread reactions" functionality works only for personal access)
   */
  const hasPersonalAccess = actorView.access.find((i) => i.userId === auth.id);

  let unreadReactions = actorView.unreadReactions;
  if (decrement) {
    unreadReactions =
      actorView.unreadReactions + (unreadReactionRemoved ? -1 : 0);
  } else {
    unreadReactions += 1;
  }

  if (
    reaction.userId !== auth.id &&
    actorView?.id === reaction.treeInfo.rootActorId &&
    hasPersonalAccess
  ) {
    yield put({
      type: UPDATE_ACTOR_VIEW.SUCCESS,
      payload: {
        ...actorView,
        unreadReactions,
      },
    });
  }
}

/**
 * Получить новую реакцию по WS
 */
function* wsCreateReaction({ payload: { model: reaction, tabId } }) {
  yield updateLayerAreaCommentsCount(reaction, 'add');

  yield updateUnreadsCounter({ reaction });
  const isHandle = yield call(isHandleWSPacket, reaction, tabId, true);
  if (!isHandle) return;
  if (yield canAddToTheStream(reaction)) {
    yield call(createReactionModel, reaction);
  }
}

/**
 * Delete reactions by WebSocket
 */
function* wsDeleteReaction({ payload: { model: reaction, tabId } }) {
  yield updateLayerAreaCommentsCount(reaction, 'delete');
  yield updateUnreadsCounter({ reaction, decrement: true });
  const isHandle = yield call(isHandleWSPacket, reaction, tabId, false);
  if (!isHandle) return;
  yield call(removeReactionModel, reaction);
}

/**
 * Обновить реакцию по WS
 */
function* wsUpdateReaction({ payload: { model: reaction, tabId } }) {
  const isHandle = yield call(isHandleWSPacket, reaction, tabId, false);
  if (!isHandle) return;
  yield call(updateReactionModel, reaction, reaction.attachments || []);
}

/**
 * Add attachments by WebSocket
 */
function* wsCreateAttachments({ payload }) {
  yield delay(1000);
  const reactions = yield select((state) => state.reactions);
  const appInit = yield select((state) => state.appInit);
  const { attachments, tabId, rootActorId } = payload;
  // Добавить вложение в реакцию
  const reactionModel = AppUtils.recursiveFind(
    reactions.list,
    attachments[0].actorId
  );
  if (!reactionModel) return;
  yield call(updateRootActorAttachments, {
    rootActorId,
    filesToAdd: attachments,
  });
  if (tabId && appInit.tabId === tabId) return;
  yield call(updateReactionModel, { ...reactionModel }, attachments);
}

/**
 * Delete attachments by WebSocket
 */
function* wsDeleteAttachments({ payload }) {
  const reactions = yield select((state) => state.reactions);
  const appInit = yield select((state) => state.appInit);
  const { attachments, tabId, rootActorId } = payload;
  yield call(updateRootActorAttachments, {
    rootActorId,
    filesToDel: attachments,
  });
  if (tabId && appInit.tabId === tabId) return;
  const removedIds = attachments.map((i) => i.id);
  // Удалить вложение из реакции
  const reactionModel = AppUtils.recursiveFind(
    reactions.list,
    attachments[0].actorId
  );
  if (!reactionModel) return;
  const filterAttachments = reactionModel.attachments.filter(
    (i) => !removedIds.includes(i.id)
  );
  yield call(updateReactionModel, { ...reactionModel }, filterAttachments);
}

/**
 * Синхронизация реакций после реконнекта WS
 */
function* wsSyncReactions() {
  const settings = yield select((state) => state.settings);
  const {
    rootActorId: actorId,
    lastReaction,
    list,
  } = yield select((state) => state.reactions);
  if (!actorId) return;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/reactions/sync/${actorId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  const { lastReactionTime } = data.data;
  if (
    !lastReactionTime ||
    (lastReaction && lastReaction.createdAt >= lastReactionTime)
  )
    return;
  yield put({ type: CLEAR_REACTIONS });
  const loadedCount = list.length;
  const customLimit =
    list.length > REACTIONS_REQ_LIMIT ? REACTIONS_REQ_LIMIT : loadedCount;
  yield put({
    type: GET_REACTIONS.REQUEST,
    payload: {
      actorId,
      view: settings.plainMode ? 'flat' : null,
      limit: customLimit,
    },
  });
}

/**
 * Отметить реакцию как прочитанную
 */
function* markAdReadReaction({ payload: id }) {
  // Задержка для анимации
  yield delay(1000);
  const reactions = yield select((state) => state.reactions);
  const reactionsList = structuredClone(reactions.list);
  const reactionModel = AppUtils.recursiveFind(reactionsList, id);
  if (!reactionModel) return;
  delete reactionModel.isNew;
  yield put({
    type: UPDATE_REACTION.SUCCESS,
    payload: { list: reactionsList },
  });
}

/**
 * Обновление
 */
export function* getReactionsWatcherSaga() {
  const requestChannel = yield actionChannel(
    GET_REACTIONS.REQUEST,
    buffers.sliding(2)
  );
  // prettier-ignore
  while (true) { // NOSONAR
    const action = yield take(requestChannel);
    yield call(getReactions, action);
    yield delay(200);
  }
}

/**
 * Передача сообщения в CDU
 */
function* wsCduChanges(action) {
  const { payload } = action;
  yield window.postMessage(
    { type: WS_CDU_CHANGES, payload },
    document.location.origin
  );
}

function* reactionsSaga() {
  yield takeEvery(CREATE_REACTION.REQUEST, createReaction);
  yield takeEvery(DELETE_REACTION.REQUEST, deleteReaction);
  yield takeEvery(UPDATE_REACTION.REQUEST, updateReaction);
  yield takeEvery(MARK_AS_READ_REACTION.REQUEST, markAdReadReaction);
  yield takeEvery(WS_CREATE_REACTION, wsCreateReaction);
  yield takeEvery(WS_DELETE_REACTION, wsDeleteReaction);
  yield takeEvery(WS_UPDATE_REACTION, wsUpdateReaction);
  yield takeEvery(WS_CREATE_ATTACHMENTS, wsCreateAttachments);
  yield takeEvery(WS_DELETE_ATTACHMENTS, wsDeleteAttachments);
  yield takeEvery(WS_SYNC_REACTIONS, wsSyncReactions);
  yield takeEvery(WS_CDU_CHANGES, wsCduChanges);
}

export default reactionsSaga;
