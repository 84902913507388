import React from 'react';
import PropTypes from 'prop-types';
import { Label, Icon, Tooltip } from 'mw-style-react';
import UserAvatar from '@control-front-end/common/components/UserAvatar';
import useIntl from 'useIntl';
import './People.scss'; // eslint-disable-line no-unused-vars
import mes from './intl';

function People({ access }) {
  const t = useIntl();

  if (!access.length)
    return (
      <>
        <Icon styleName="people__icon" type="client" />
        <Label styleName="people__placeholder" value={`${t(mes.to)}:`} />
      </>
    );

  const els = [];
  const maxChipsCount = 6;
  const selectedMore = access.length - maxChipsCount;
  const sliceSelect = access.slice(0, maxChipsCount);
  const avatars = sliceSelect.map((item) => (
    <Tooltip styleName="people__tooltip" key={item.userId} value={item.name}>
      <div styleName="people__avatar">
        <UserAvatar
          src={item.avatar}
          label={item.name}
          size="small"
          userType={item.userType}
        />
      </div>
    </Tooltip>
  ));

  els.push(avatars);

  if (selectedMore > 0) {
    els.push(
      <Label
        key="label"
        styleName="people__count"
        value={` +${selectedMore}`}
        fontWeight="semibold"
      />
    );
  }
  return els;
}

People.propTypes = {
  access: PropTypes.array.isRequired,
};

export default People;
