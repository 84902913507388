import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import WB from 'control-whiteboard';
import useIntl from 'useIntl';
import { Icon, Label, Tooltip } from 'mw-style-react';
import Title from '@control-front-end/common/components/Title';
import mes from './intl';
import './WhiteBoard.scss';

/**
 * WhiteBoard
 */
function WhiteBoard({ onDestroy }) {
  const t = useIntl();
  const contentRef = useRef();
  const wBRef = useRef();
  const titleRef = useRef();
  const stateRef = useRef(false);
  const [title, setTitle] = useState(t(mes.newPicture));
  titleRef.current = title || '';

  const handleDestroy = (pictureObject) => {
    onDestroy({
      title: titleRef.current,
      isChanged: stateRef.current,
      pictureObject,
    });
  };

  const handleChange = () => {
    stateRef.current = true;
  };

  useEffect(() => {
    wBRef.current = new WB({
      container: contentRef.current,
      onDestroy: handleDestroy,
      onChange: handleChange,
    });
  }, []);

  const wbContent = (
    <div styleName="wb">
      <div styleName="wb__wrap">
        <div styleName="wb__title">
          <Title
            title={title}
            type="secondary"
            singleLine={true}
            canEdit={true}
            onSubmit={setTitle}
          />
        </div>
        <Tooltip styleName="wb__tooltip" value={t(mes.saveAndClose)}>
          <Icon
            styleName="wb__close"
            type="close"
            onClick={() => wBRef.current.destroy()}
          />
        </Tooltip>
        <div ref={contentRef} styleName="wb__content" />
        <div styleName="wb__discl">
          <Label value={t(mes.whiteboardDiscl)} fontSize="small" />
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(wbContent, document.getElementById('mainRoot'));
}

WhiteBoard.propTypes = {
  onDestroy: PropTypes.func.isRequired,
};

export default WhiteBoard;
