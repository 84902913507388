import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Label, Tooltip, Stack } from 'mw-style-react';
import useIntl from 'useIntl';
import { PERMISSIONS } from '@control-front-end/common/constants/permissions';
import SelectUsers from '@control-front-end/common/components/SelectUsers';
import { GET_USER } from 'constants';
import { checkUserPermissions } from '../../../../selectors';
import mes from './intl';
import '../../ActorGeneralFields.scss';

function SingleAccountUser({ userId, onChange }) {
  const t = useIntl();
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const checkPermissions = useSelector(checkUserPermissions);
  const canViewUsers = checkPermissions([PERMISSIONS.USERS_READONLY]);
  const canManageSystemActors = checkPermissions([
    PERMISSIONS.SYSTEM_ACTORS_MANAGEMENT,
  ]);

  const handleGetUser = (userId) => {
    dispatch({
      type: GET_USER.REQUEST,
      payload: { userId },
      callback: (data) => setUser(data),
    });
  };

  useEffect(() => {
    if (!userId) return;
    if (canViewUsers) {
      handleGetUser(userId);
    } else {
      setUser({ id: +userId, nick: 'Access Denied' });
    }
  }, []);

  return (
    <SelectUsers
      multiselect={false}
      fullModel={true}
      bordered={true}
      unspaced={true}
      label={
        <Stack.H size="xxsmall" alignItems="center">
          <Label
            fontSize="small"
            value={t(mes.saUser)}
            color={Label.COLOR.gray}
          />
          <Tooltip
            styleName="agf__data__ref__tooltip"
            value={t(mes.saUserInfo)}
          >
            <Icon size="small" type="info" />
          </Tooltip>
        </Stack.H>
      }
      value={user}
      isDisabled={!canManageSystemActors}
      onChange={({ value }) => {
        onChange({ systemObjId: value.id === user.id ? null : value.id });
        setUser(value.id === user.id ? {} : value);
      }}
    />
  );
}

SingleAccountUser.propTypes = {
  userId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SingleAccountUser;
