import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popover, Stack, Icon, Tooltip, cr } from 'mw-style-react';

import {
  ADD_WIDGET,
  UPDATE_WIDGET,
  WIDGETS_NAMES,
  SET_STARRED_SCRIPTS,
} from 'constants';
import { GET_SCRIPTS } from '@control-front-end/common/constants/scripts';
import ListWithSearch from '@control-front-end/common/components/ListWithSearch';

import scss from './CustomBar.scss';

function CustomBar({ actorId }) {
  const { list: starredScripts } = useSelector((state) => state.starredScripts);
  const customBarRef = useRef();
  const dispatch = useDispatch();
  const widgets = useSelector((state) => state.widgets);
  const runningScripts = widgets.filter(
    (widget) => widget?.actorId === actorId
  );

  useEffect(() => {
    if (!starredScripts.length)
      dispatch({
        type: GET_SCRIPTS.REQUEST,
        callback: (data) =>
          dispatch({ type: SET_STARRED_SCRIPTS, payload: data }),
      });
  }, []);

  const handleRunScriptWidget = (scriptData, multi = true) => {
    const rect = customBarRef.current.getBoundingClientRect();
    const parentPosition = {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };

    const toggleScriptVisibility = (scriptData, isVisible) => {
      dispatch({
        type: UPDATE_WIDGET,
        payload: {
          id: scriptData.id,
          updates: { isVisible: isVisible ?? !scriptData.isVisible },
        },
      });
    };

    const existingScript = runningScripts.find(
      (widget) =>
        widget.id === scriptData.id + actorId &&
        widget.name === WIDGETS_NAMES.scriptWidget
    );

    if (!multi) {
      // Find the visible script if single mode is enabled
      const visibleScript = runningScripts.find(
        (widget) =>
          widget.name === WIDGETS_NAMES.scriptWidget && widget.isVisible
      );

      if (visibleScript) {
        // Hide the currently visible script
        toggleScriptVisibility(visibleScript, false);
      }
    }

    // Find if the script is already running
    if (existingScript) {
      // If the script is already running, toggle its visibility
      toggleScriptVisibility(existingScript);
    } else {
      // If the script is not running, add it as a new widget
      dispatch({
        type: ADD_WIDGET,
        payload: {
          ...omit(scriptData, ['ref']),
          id: scriptData.id + actorId,
          scriptRef: scriptData.ref || scriptData.id,
          name: WIDGETS_NAMES.scriptWidget,
          parentPosition,
          actorId,
          isVisible: true,
        },
      });
    }
  };

  return (
    <Stack.H className={scss.customBarWrapper} size="none">
      <Stack.H
        size="xsmall"
        forwardRef={customBarRef}
        className={scss.customBar}
      >
        {starredScripts.map((script) => {
          return (
            <Tooltip value={script.title} position="top" key={script.id}>
              <Button
                key={script.id}
                style={{
                  backgroundImage: `url(${script?.pictureUrl})`,
                }}
                className={scss.scriptButton}
                onClick={() => handleRunScriptWidget(script)}
                icon={script?.pictureUrl ? null : <Icon type="star_full" />}
                type="quinary"
                size="small"
              />
            </Tooltip>
          );
        })}
      </Stack.H>
      {cr([
        starredScripts.length > 0,
        <Popover
          content={({ onClose }) => (
            <ListWithSearch
              onItemClick={(script) => {
                handleRunScriptWidget(script);
                onClose();
              }}
              actionType={GET_SCRIPTS.REQUEST}
              listWrapperClassName={scss.scriptsListWrapper}
              listClassName={scss.scriptsList}
              itemClassName={scss.scriptItem}
            />
          )}
          anchors={{
            binding: Popover.ANCHOR.center_top,
            content: Popover.ANCHOR.left_bottom,
          }}
          topLevel
        >
          {({ onToggle }) => (
            <Button
              className={scss.scripButton}
              onClick={(e) => {
                e.stopPropagation();
                onToggle();
              }}
              icon={<Icon type="more" />}
              type="quinary"
              size="small"
            />
          )}
        </Popover>,
      ])}
    </Stack.H>
  );
}

CustomBar.propTypes = {
  actorId: PropTypes.string.isRequired,
};

export default CustomBar;
