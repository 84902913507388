import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Chip, Tooltip, Icon, Select, MenuItem, Utils } from 'mw-style-react';
import cn from 'classnames';
import { useIntl, useSearchList, useOutsideClick } from 'hooks';
import AppUtils from '@control-front-end/utils/utils';
import { GET_ACTORS_ACCOUNTS } from '@control-front-end/common/constants/actorAccounts';
import SelectActor from './components/SelectActor';
import mes from './intl';
import './AccountChip.scss';

function AccountChip(props) {
  const { data = {}, error, accountType, onRemove, onChange } = props;
  const {
    id,
    currencyName,
    accountName,
    amount,
    actor = {},
    incomeType,
  } = data;
  const t = useIntl();
  const chipEditRef = useRef();
  const accSelectRef = useRef();
  const dataRef = useRef();
  const [editMode, setEditMode] = useState(!data.id && !error);
  const [incomeTypes, setIncomeTypes] = useState([]);
  const shortAccType = accountType[0].toUpperCase();

  const {
    list: actorAccounts,
    makeSearch,
    onScroll,
  } = useSearchList({
    actionType: GET_ACTORS_ACCOUNTS.REQUEST,
    skip: !data.actorId,
    // turn off strict check list.length < limit
    // because getActorsAccounts saga returns 1 item merged from 2 of api response
    // e.g. with step 10 we get 5 items and strict check counts this as the end of list
    strictEndCheck: false,
    settings: { step: 20, scrollToLoad: 20 },
    localState: true,
    actorId: data.actorId,
    accountType,
  });

  const handleClickOutside = (e) => {
    const isAccountSelect = e.target.closest('.selectMenuItem');
    const isActorSelect = e.target.closest('#actorsList');
    const isChipEdition = isActorSelect || isAccountSelect;
    if (isChipEdition) return;
    if (!dataRef.current) {
      // удаляем пустой
      onRemove();
    } else {
      // коллапсим
      setEditMode(false);
      // ошибка для частично заполненного
      if (!dataRef.current || !dataRef.current.id) {
        onChange({ value: { ...dataRef.current }, error: true });
      }
    }
  };
  useOutsideClick({ ref: chipEditRef, callback: handleClickOutside });

  useEffect(() => {
    if (data.id && data.actor) {
      dataRef.current = data;
    }
  }, []);

  const handleSelectActor = ({ value }) => {
    value.formColor = value.color;
    const newData = { actor: value, actorId: value.id };
    onChange({ value: newData });
    dataRef.current = newData;
    makeSearch({ actorId: value.id, accountType });
  };

  const handleSelectAccount = ({ value }) => {
    const acc = actorAccounts.find((i) => value === i.key);
    const { debit, credit } = acc;
    const newData = {
      ...data,
      nameId: acc.nameId,
      accountName: acc.accountName,
      currencyId: acc.currencyId,
      currencyName: acc.currencyName,
    };
    onChange({ value: newData });
    dataRef.current = newData;
    setIncomeTypes([debit, credit]);
  };

  const handleSelectIncomeType = ({ value }) => {
    const acc = incomeTypes.find((i) => i.incomeType === value);
    const newData = {
      ...data,
      id: acc.id,
      incomeType: value,
      amount: acc.amount,
    };
    onChange({ value: newData, error: false });
    dataRef.current = newData;
  };

  const getShortLabel = (label) => {
    if (!label) return '';
    return label
      .trim()
      .split(/\s+/)
      .map((n) => (n[0] ? n[0].toUpperCase() : ''))
      .splice(0, 2)
      .join('');
  };

  if (editMode) {
    return (
      <div ref={chipEditRef} styleName={cn('formula__chip__edit', { error })}>
        <SelectActor
          value={data.actor || {}}
          autoFocus={!data.actor}
          onChange={handleSelectActor}
        />
        {data.actor ? (
          <Select
            ref={accSelectRef}
            styleName="formula__chip__edit__select"
            autoFocus={data.actorId && !data.nameId}
            label={data.nameId ? null : t(mes.accLabel)}
            value={data.nameId ? `${data.nameId}+${data.currencyId}` : ''}
            onChange={handleSelectAccount}
            onMenuScroll={onScroll}
            popoverOnTop
          >
            {actorAccounts.length ? (
              actorAccounts.map((i) => (
                <MenuItem
                  key={i.key}
                  className="selectMenuItem"
                  value={i.key}
                  size="small"
                  label={`${i.accountName} ${i.currencyName} (${shortAccType})`}
                  labelTooltip
                />
              ))
            ) : (
              <MenuItem
                value={null}
                label="No accounts"
                visibility="disabled"
              />
            )}
          </Select>
        ) : null}
        {data.actor && data.nameId ? (
          <Select
            styleName="formula__chip__edit__select"
            label={data.incomeType ? null : t(mes.type)}
            value={data.incomeType}
            autoFocus={data.actorId && data.nameId && !data.incomeType}
            onChange={handleSelectIncomeType}
            popoverOnTop
          >
            {incomeTypes.map((i) => (
              <MenuItem
                key={i.id}
                className="selectMenuItem"
                styleName="formula__chip__edit__select__item"
                value={i.incomeType}
                size="small"
                label={`${Utils.toUpperLatter(i.incomeType)}`}
              >
                {AppUtils.toNumberFormat(i.amount)}
              </MenuItem>
            ))}
          </Select>
        ) : null}
      </div>
    );
  }

  const tooltipText = id
    ? `${actor.title}, ${accountName} ${currencyName}; ${Utils.toUpperLatter(
        incomeType
      )} - ${amount}`
    : t(mes.accNotSelected);

  const getChipLabel = () => {
    const label = [actor.title, accountName, currencyName, incomeType]
      .map((i) => getShortLabel(i))
      .join(';');
    return id && incomeType
      ? `${label} - ${AppUtils.toNumberFormat(amount)}`
      : label;
  };

  return (
    <div styleName="formula__chip" onClick={() => setEditMode(true)}>
      <Tooltip styleName="formula__chip__tooltip" value={tooltipText}>
        <Chip
          styleName={cn('formula__chip__item', { error })}
          size="small"
          bgColor={actor.formColor || '#ffffff'}
          borderColor={actor.formColor || '#ACB3BE'}
          label={getChipLabel()}
          closeIcon={false}
          type="rectangular"
          fontWeight="normal"
        />
      </Tooltip>
      <div
        styleName="formula__chip__actions"
        style={{
          backgroundColor: actor.formColor || '#ffffff',
          borderColor: actor.formColor || '#ACB3BE',
        }}
      >
        <Tooltip styleName="formula__chip__tooltip" value={t(mes.remove)}>
          <Icon
            styleName="formula__chip__actions__icon"
            type="close"
            size="small"
            onClick={onRemove}
          />
        </Tooltip>
      </div>
    </div>
  );
}

AccountChip.propTypes = {
  data: PropTypes.object,
  error: PropTypes.bool,
  accountType: PropTypes.oneOf(['fact', 'plan']),
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
};

export default AccountChip;
