import { put, select } from 'redux-saga/effects';
import { keyBy } from 'lodash';

import { MANAGE_LAYER_ACTORS } from '@control-front-end/common/constants/graphLayers';

export const getGraphElementsMap = (layersList) =>
  layersList.reduce(
    (acc, layer, layerIndex) => {
      (layer.nodes || []).forEach((node, nodeIndex) => {
        acc.nodes[`[${layerIndex}].nodes[${nodeIndex}]`] = node;
      });
      (layer.edges || []).forEach((edge, nodeIndex) => {
        acc.edges[`[${layerIndex}].edges[${nodeIndex}]`] = edge;
      });
      return acc;
    },
    { nodes: {}, edges: {} }
  );

export function* removeNodesFromGraph(canRemove) {
  const layersList = yield select((state) => state.graphLayers.list);

  const layersListCopy = structuredClone(layersList);
  const graphElementsMap = getGraphElementsMap(layersListCopy);
  const nodesToRemove = Object.values(graphElementsMap.nodes).filter(canRemove);
  const nodesById = keyBy(Object.values(graphElementsMap.nodes), 'data.id');

  nodesToRemove.forEach((n) => (n.status = 'removed'));

  Object.values(graphElementsMap.edges)
    .filter((e) => nodesById[e.data.source] || nodesById[e.data.target])
    .forEach((n) => (n.status = 'removed'));

  yield put({
    type: MANAGE_LAYER_ACTORS.SUCCESS,
    payload: { list: layersListCopy },
  });
}
