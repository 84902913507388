import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Chip,
  DateUtils,
  Icon,
  Label,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Badge,
  Tooltip,
} from 'mw-style-react';
import useIntl from 'useIntl';
import { DATE_FORMAT_4 } from 'constants';
import { BulkActionsHeader } from 'components';
import './ExportItemsList.scss';
import mes from './intl';

/**
 * Список элементов для экспорта
 */
function ExportItemsList({ type, list, handleOnChange }) {
  const t = useIntl();
  const [selectedIds, setSelectedIds] = useState([]);

  // сброс выделения при смене табы
  useEffect(() => {
    setSelectedIds([]);
  }, [type]);

  const handleRemoveItems = (ids) => {
    const newList = list.filter((i) => !ids.includes(i.id));
    handleOnChange({ id: type, value: newList });
    const restOfSelected = selectedIds.filter((i) => !ids.includes(i));
    setSelectedIds(restOfSelected);
  };

  const handleSelection = (id, selected) => {
    if (selected) {
      const selectedIdsCopy = selectedIds.slice();
      selectedIdsCopy.push(id);
      setSelectedIds(selectedIdsCopy);
    } else {
      const restOfSelected = selectedIds.filter((item) => item !== id);
      setSelectedIds(restOfSelected);
    }
  };

  const handleBulkSelect = (action) => {
    if (action === 'add') {
      const allIds = list.map((i) => i.id);
      setSelectedIds(allIds);
    } else {
      setSelectedIds([]);
    }
  };

  const renderItemRow = (item) => {
    return (
      <TableRow key={item.id} id={item.id} styleName="table__row">
        <TableCell styleName="table__cell title">
          <div
            onClick={(e) => e.stopPropagation()}
            onDoubleClick={(e) => e.stopPropagation()}
          >
            <Checkbox
              value={!!selectedIds.find((sId) => sId === item.id)}
              onChange={({ value }) => handleSelection(item.id, value)}
            />
          </div>
          <Badge
            bgColor={item.color || '#ffffff'}
            borderColor={item.color || '#ACB3BE'}
          />
          <Label value={item.title} />
        </TableCell>
        {type === 'actors' ? (
          <TableCell styleName="table__cell tpl">
            <Chip
              styleName="chip"
              size="small"
              closeIcon={false}
              type="rectangular"
              fontWeight="normal"
              label={item.formTitle}
            />
          </TableCell>
        ) : (
          <TableCell styleName="table__cell empty" />
        )}
        <TableCell styleName="table__cell date">
          {item.addedAt ? (
            <Label value={DateUtils.toDate(item.addedAt, DATE_FORMAT_4)} />
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell styleName="table__cell close">
          <div onClick={() => handleRemoveItems([item.id])}>
            <Tooltip styleName="tooltip" value={t(mes.removeFromExport)}>
              <Icon size="small" type="close" />
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  if (!list.length)
    return (
      <div styleName="table__empty">
        <Icon size="xlarge" type="export" />
        <Label value={t(mes[`${type}ExportListEmpty`])} />
      </div>
    );

  return (
    <Table
      styleName="table"
      paddingSize="small"
      columnSize={['40%', type === 'actors' ? '25%' : 0, '25%', 24]}
    >
      <TableHead fixed={true} styleName="table__head">
        <TableRow styleName="table__row" rowSize="small">
          <TableCell styleName="table__cell title">
            <BulkActionsHeader
              objType={type.slice(0, -1)}
              actions={['remove']}
              list={list}
              total={list.length}
              selectedIds={selectedIds}
              handleBulkSelect={handleBulkSelect}
              handleRemoveSelected={() => handleRemoveItems(selectedIds)}
            />
          </TableCell>
          {type === 'actors' ? (
            <TableCell styleName="table__cell tpl">
              {t(mes.actorType)}
            </TableCell>
          ) : (
            <TableCell styleName="table__cell" />
          )}
          <TableCell styleName="table__cell date">
            {t(mes.addedToExport)}
          </TableCell>
          <TableCell styleName="table__cell close" />
        </TableRow>
      </TableHead>
      <TableBody styleName="table__body">
        {list.map((i) => renderItemRow(i))}
      </TableBody>
    </Table>
  );
}

ExportItemsList.propTypes = {
  type: PropTypes.oneOf(['actors', 'forms']),
  list: PropTypes.array,
  handleOnChange: PropTypes.func.isRequired,
};

export default ExportItemsList;
