import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useIntl from 'useIntl';
import AppUtils from '@control-front-end/utils/utils';
import { GET_ACTORS_ACCOUNTS } from '@control-front-end/common/constants/actorAccounts';
import { Label, Select, MenuItem, Tooltip } from 'mw-style-react';
import './SelectActorsAccount.scss';
import mes from './intl';

/**
 * Components for selecting actors' account
 * @returns {JSX.Element}
 * @constructor
 * @deprecated wiil be removed, use SelectActorsAccountPair
 */
function SelectActorsAccount({
  actorId,
  value,
  accountType = 'fact',
  incomeType,
  currency,
  getCustomBalance,
  isDisabled = false,
  withPrivs = false,
  liveSearch = false,
  size = 'large',
  onChange,
  fieldClassName = '',
  ...rest
}) {
  const t = useIntl();
  const dispatch = useDispatch();
  const [actorAccounts, setActorAccounts] = useState([]);

  /**
   * Get actor's accounts for selection
   */
  const selectCurrentActorAccount = (accs) => {
    if (!accs || accs.length === 0) {
      return;
    }

    const currentCurrencyId = value?.currencyId;
    const acc = accs.find(
      ({ nameId, currencyId }) =>
        value?.nameId === nameId && +currentCurrencyId === +currencyId
    );

    onChange(acc || accs[0]);
  };

  const getActorAccounts = () => {
    dispatch({
      type: GET_ACTORS_ACCOUNTS.REQUEST,
      payload: { actorId, accountType, localState: true, withPrivs },
      callback: (accs) => {
        setActorAccounts(accs);
        selectCurrentActorAccount(accs);
      },
    });
  };

  useEffect(() => {
    setActorAccounts([]);
    const presettedAccount = !!value && isDisabled;
    if (!actorId || presettedAccount) return;
    getActorAccounts();
  }, [actorId]);

  const handleSelectAccount = ({ value }) => {
    const acc = actorAccounts.find((i) => value === i.key);
    onChange(acc);
  };

  useEffect(() => {
    if (!actorAccounts.length || !value || (value.debit && value.credit))
      return;
    const actorAccount = actorAccounts.find(
      (acc) =>
        acc.nameId === value.nameId && +acc.currencyId === +value.currencyId
    );
    if (actorAccount) onChange(actorAccount);
  }, [actorAccounts, value]);

  /**
   * Formatted account balance
   */
  const getBalance = useCallback(() => {
    if (!value || !value[incomeType]) return '0';
    const acc = value[incomeType];
    const { currencyType, precision, symbol, currencyName } = acc;
    const currencyParams = { type: currencyType, symbol, precision };
    return `${AppUtils.simpleFormattedAmount(
      acc.amount,
      currencyParams
    )} ${currencyName}`;
  }, [value, incomeType]);

  /**
   * Render account balance with tooltip
   */
  const renderBalance = useCallback(() => {
    if (!value[incomeType]) return null;
    const acc = value[incomeType];
    const balance = getCustomBalance ? getCustomBalance(acc) : getBalance();
    return (
      <Tooltip styleName="account__balance__tooltip" value={balance}>
        <Label styleName="account__balance" value={balance} />
      </Tooltip>
    );
  }, [value, incomeType, getCustomBalance]);

  /**
   * Filter actor's accounts by currency (if specified)
   */
  const renderAvailableAccounts = useCallback(
    (accounts) => {
      const visibleAccounts =
        currency && currency.id
          ? accounts.filter((acc) => +acc.currencyId === +currency.id)
          : accounts;
      if (!visibleAccounts.length)
        return (
          <MenuItem
            value={null}
            label={t(mes.noAccountsWithCurrency, {
              currencyName: currency.name,
            })}
            labelTooltip
            visibility="disabled"
          />
        );
      return visibleAccounts.map((i) => (
        <MenuItem
          key={i.key}
          value={i.key}
          size="small"
          label={`${i.accountName}, ${i.currencyName}`}
          labelTooltip
          visibility={
            withPrivs && i.privs && !i.privs.modify ? 'disabled' : 'visible'
          }
        />
      ));
    },
    [currency, actorAccounts]
  );

  return (
    <div styleName="account" className={fieldClassName}>
      <Select
        styleName="account__select"
        popoverOptions={{ padding: 4 }}
        size={size}
        bordered={true}
        type={liveSearch ? 'autocomplete' : 'default'}
        autoSelect={false}
        placeholder={t(mes.selectAccount)}
        value={
          value[incomeType] ? `${value.accountName}, ${value.currencyName}` : ''
        }
        onChange={handleSelectAccount}
        popoverOnTop
        visibility={actorId && !isDisabled ? 'visible' : 'disabled'}
        {...rest}
      >
        {actorAccounts.length ? (
          renderAvailableAccounts(actorAccounts)
        ) : (
          <MenuItem value={null} label="No accounts" visibility="disabled" />
        )}
      </Select>
      {renderBalance()}
    </div>
  );
}

SelectActorsAccount.propTypes = {
  value: PropTypes.object,
  actorId: PropTypes.string,
  accountType: PropTypes.string,
  incomeType: PropTypes.string,
  withPrivs: PropTypes.bool,
  liveSearch: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default SelectActorsAccount;
