import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useModal } from 'hooks';
import { DATE_FORMAT_2, DATE_FORMAT_4 } from 'constants';
import AppUtils from '@control-front-end/utils/utils';
import FormUtils from '@control-front-end/utils/formUtils';
import { Label, Icon, Badge, Stack, DateUtils } from 'mw-style-react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import ActorFieldValue from './ActorFieldValue';
import AccessDiffValue from './AccessDiffValue';
import FilesDiffValue from './FilesDiffValue';
import './HistoryDiffRow.scss';

const NO_VALUE = '-';

/**
 * One actor data change row
 * @param props
 * @returns {*}
 * @constructor
 */
function HistoryDiffRow(props) {
  const { id, field, label, formTitle, prevValue, nextValue } = props;
  const accId = useSelector((state) => state.accounts.active);
  const { open: openFilePreview } = useModal('FilePreview', {});

  const isUploadValue = useMemo(
    () =>
      (Array.isArray(prevValue) && prevValue.length && prevValue[0].fileName) ||
      (Array.isArray(nextValue) && nextValue.length && nextValue[0].fileName),
    [prevValue, nextValue]
  );

  /**
   * File clickable preview
   */
  const renderFile = useCallback((file) => {
    const { title: fileTitle, type, fileName } = file;
    const filePath =
      file.filePath || AppUtils.makeAppUrl(`/download/${fileName}`);
    const isImage = type?.includes('image') || type === 'napkin';
    return (
      <div
        styleName="diff__item__img"
        onClick={() => openFilePreview({ ...file, files: [file] })}
      >
        {isImage ? (
          <img src={filePath} alt="" />
        ) : (
          <>
            <Icon type="file" />
            <Label value={fileTitle} />
          </>
        )}
      </div>
    );
  }, []);

  /**
   * Actor color value
   */
  const renderColor = useCallback(
    (color, status) =>
      color ? (
        <Stack
          styleName={status}
          horizontal
          alignItems="center"
          size={Stack.SIZE.xsmall}
        >
          <Badge size="large" bgColor={color} borderColor="#fff" />
          <Label value={color} />
        </Stack>
      ) : (
        NO_VALUE
      ),
    []
  );

  /**
   * Formatted date value
   */
  const renderDate = useCallback((value, status) => {
    let date;
    if (typeof value === 'object') {
      const { startDate } = value;
      const startOfDay = DateUtils.startOf(startDate * 1000, 'day') / 1000;
      const format = startDate === startOfDay ? DATE_FORMAT_2 : DATE_FORMAT_4;
      date = AppUtils.makeCalendarItemValue(value, format);
    } else {
      date = DateUtils.toDate(value, DATE_FORMAT_4);
    }
    return (
      <Stack
        styleName={status}
        horizontal
        alignItems="center"
        size={Stack.SIZE.xsmall}
      >
        <Icon
          size="small"
          type="calendar"
          colorType={status !== 'removed' ? Icon.COLOR.primary : null}
        />
        <Label value={date} />
      </Stack>
    );
  }, []);

  /**
   * Actor picture object value (lasso area, napkin or image)
   */
  const renderPictureObject = useCallback((pictureObject) => {
    if (!pictureObject?.img) return <Label value={NO_VALUE} />;
    const { img, type } = pictureObject;
    const filePath =
      type === 'image' ? AppUtils.makeAppUrl(`/download/${img}`) : img;
    return renderFile({ title: img, type, fileName: img, filePath });
  }, []);

  /**
   * Actor avatar value
   */
  const renderPicture = useCallback((picture) => {
    if (!picture.length) return <Label value={NO_VALUE} />;
    return renderFile({ title: picture, type: 'image/png', fileName: picture });
  }, []);

  /**
   * Actor value (added actor card)
   */
  const renderActorChip = useCallback((actorId) => {
    if (!actorId) return <Label value={NO_VALUE} />;
    return <ActorFieldValue id={actorId} accId={accId} />;
  }, []);

  /**
   * Actor links
   */
  const renderActorLink = useCallback(([value]) => {
    if (!value?.actorId) return <Label value={NO_VALUE} />;
    return (
      <Stack.H size={Stack.SIZE.xxsmall} alignItems="center">
        <Icon type="connection" />
        {FormUtils.getActorChip(
          { value: value.actorId, title: value.actorTitle },
          accId
        )}
      </Stack.H>
    );
  }, []);

  /**
   * Script settings value
   */
  const renderAppSettings = useCallback(
    (appSettings) =>
      Object.keys(appSettings).map((key) => (
        <Label key={`${id}_${key}`} value={`${key}: ${appSettings[key]}`} />
      )),
    []
  );

  /**
   * Split view with text value diff
   */
  const renderTextDiff = useCallback((pValue, nValue) => {
    const customStyles = {
      variables: {
        light: {
          removedBackground: '#FFE5E5',
          addedBackground: '#E4F9E6',
          emptyLineBackground: '#ffffff',
        },
      },
      line: {
        'pre, span': {
          fontFamily: '"Open Sans", sans-serif',
          lineHeight: '22px',
          padding: 0,
        },
      },
      marker: {
        pre: {
          color: '#6E7F96 !important',
        },
      },
    };
    return (
      <ReactDiffViewer
        styles={customStyles}
        oldValue={pValue.length ? AppUtils.removeBbCode(pValue) : NO_VALUE}
        newValue={nValue.length ? AppUtils.removeBbCode(nValue) : NO_VALUE}
        splitView={true}
        hideLineNumbers={true}
        showDiffOnly={false}
        compareMethod={DiffMethod.WORDS_WITH_SPACE}
      />
    );
  }, []);

  /**
   * Actor form values
   */
  const makeFormFieldValue = useCallback((fieldValue, status = null) => {
    if (AppUtils.isUndefined(fieldValue)) return NO_VALUE;
    let value;
    switch (fieldValue.type) {
      case 'actor':
        value = FormUtils.getActorChip(fieldValue, accId);
        break;
      case 'workspaceMembers':
        value = FormUtils.getUserChip(fieldValue, accId);
        break;
      case 'accountName':
      case 'currency':
        value = FormUtils.getActorChip(
          { value: fieldValue.id, title: fieldValue.title },
          accId,
          { interactiveChips: false }
        );
        break;
      case 'fact':
        value = `${fieldValue.accountName}, ${fieldValue.currencyName}`;
        break;
      default:
        if (fieldValue.startDate) {
          value = renderDate(fieldValue, status);
        } else if (typeof fieldValue === 'boolean') {
          value = fieldValue ? 'Yes' : 'No';
        } else {
          const stringValue = fieldValue.title || fieldValue;
          value = stringValue.length ? stringValue : NO_VALUE;
        }
    }
    if (value === NO_VALUE) return value;
    return (
      <div styleName={status}>
        {typeof value === 'string' ? <Label value={value} /> : value}
      </div>
    );
  }, []);

  /**
   * Multiple values list (dropdown or multiSelect item)
   */
  const renderValuesList = useCallback(() => {
    const removed = AppUtils.diffArrayObj(prevValue, nextValue, 'value').map(
      (i) => i.value
    );
    const added = AppUtils.diffArrayObj(nextValue, prevValue, 'value').map(
      (i) => i.value
    );
    const prevValuesList = prevValue.map((item) => {
      const status = removed.includes(item.value) ? 'removed' : null;
      return makeFormFieldValue(item, status);
    });
    const nextValuesList = nextValue.map((item) => {
      const status = added.includes(item.value) ? 'added' : null;
      return makeFormFieldValue(item, status);
    });
    return (
      <div key={`${id}_${field}_row`} styleName="diff__item">
        <Stack
          styleName="diff__item__content"
          horizontal
          size={Stack.SIZE.xsmall}
        >
          {prevValuesList}
        </Stack>
        <Stack
          styleName="diff__item__content"
          horizontal
          size={Stack.SIZE.xsmall}
        >
          {nextValuesList}
        </Stack>
      </div>
    );
  }, [prevValue, nextValue]);

  /**
   * Render JSON field value in readable format
   */
  const renderFormattedJSON = useCallback(
    (json) => JSON.stringify(JSON.parse(json), null, 2),
    []
  );

  const renderDefaultField = useCallback(
    (value, status) =>
      Array.isArray(value)
        ? value.map((item) => makeFormFieldValue(item, status))
        : makeFormFieldValue(value, status),
    []
  );

  const renderAccountField = useCallback(
    (value, status) =>
      Array.isArray(value)
        ? makeFormFieldValue(value[0], status)
        : makeFormFieldValue(value, status),
    []
  );

  const renderModelFields = useCallback(() => {
    let func;
    switch (field) {
      case 'access':
        return (
          <AccessDiffValue
            key={id}
            id={id}
            field={field}
            prevValue={prevValue}
            nextValue={nextValue}
          />
        );
      case 'attachments':
        return (
          <FilesDiffValue id={id} prevValue={prevValue} nextValue={nextValue} />
        );
      case 'description':
        return renderTextDiff(prevValue, nextValue);
      case 'color':
        func = renderColor;
        break;
      case 'picture':
        func = renderPicture;
        break;
      case 'pictureObject':
        func = renderPictureObject;
        break;
      case 'links':
        func = renderActorLink;
        break;
      case 'cardActorId':
      case 'appId':
        func = renderActorChip;
        break;
      case 'appSettings':
        func = renderAppSettings;
        break;
      case 'data.endDate':
      case 'data.startDate':
        // only for events with another date format in data
        if (formTitle === 'Events') func = renderDate;
        break;
      case 'data.filter':
      case 'data.source':
        // for JSON fields in system forms
        if (AppUtils.isJSON(prevValue) && AppUtils.isJSON(nextValue)) {
          return renderTextDiff(
            renderFormattedJSON(prevValue),
            renderFormattedJSON(nextValue)
          );
        }
        func = renderDefaultField;
        break;
      case 'account':
        func = renderAccountField;
        break;
      case 'forms':
        return renderTextDiff(
          prevValue?.map((i) => i.title).join(', ') || '',
          nextValue?.map((i) => i.title).join(', ') || ''
        );
      default:
        // for text values always show diff in side view
        if (typeof prevValue === 'string' && typeof nextValue === 'string') {
          return renderTextDiff(prevValue, nextValue);
        }
        if (isUploadValue) {
          return (
            <FilesDiffValue
              id={id}
              prevValue={prevValue}
              nextValue={nextValue}
            />
          );
        }
        if (Array.isArray(prevValue) && Array.isArray(nextValue))
          return renderValuesList();
        func = renderDefaultField;
        break;
    }

    return (
      <div key={`${id}_${field}_row`} styleName="diff__item">
        <Stack styleName="diff__item__content" size={Stack.SIZE.xsmall}>
          {func(prevValue, 'removed')}
        </Stack>
        <Stack styleName="diff__item__content" size={Stack.SIZE.xsmall}>
          {func(nextValue, 'added')}
        </Stack>
      </div>
    );
  }, [prevValue, nextValue]);

  return (
    <Stack styleName="diff" fullWidth size={Stack.SIZE.small}>
      {label}
      {renderModelFields()}
    </Stack>
  );
}

HistoryDiffRow.propTypes = {
  id: PropTypes.number.isRequired,
  field: PropTypes.string,
  label: PropTypes.element,
  prevValue: PropTypes.any,
  nextValue: PropTypes.any,
};

export default HistoryDiffRow;
