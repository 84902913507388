import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Utils, cr } from 'mw-style-react';
import AppUtils from '@control-front-end/utils/utils';
import { ActorColors } from 'components';
import { polygonToCellPositions } from '@control-front-end/utils/modules/utilsCellCoords';
import scss from './NodeTitle.scss';

function Counter({ className, svgClassName, value, ...props }) {
  if (!value && value !== 0) return null;
  return (
    <div className={cn(className, scss.counter)} {...props}>
      <div className={cn(scss.iconCounter, scss.icon, svgClassName)} />
      {value}
    </div>
  );
}

/**
 * Подписи к акторам
 */
function NodeTitle(props) {
  const {
    id,
    title,
    width,
    selected,
    layerStarred,
    semitransp,
    hide,
    linksCount,
    balance,
    balanceFormatted,
    balanceVector,
    countOneLevel,
    pinned,
    status,
    allColors,
    graphsCount,
    layersCount,
    actorsCount,
    tplsCount,
    formType,
    formTitle,
    pictureUrl,
    actorNumber,
    pictureObject,
    areaPicture,
    layerSettings = {},
    className,
    iconClassName,
    readOnly = false,
    polygon = false,
    cellPosition,
    showNodesCoordinates,
  } = props;

  const coordinates = useMemo(() => {
    return (
      props.isStateMarkup && props.polygon
        ? polygonToCellPositions(props.polygon)
        : [cellPosition]
    ).filter(Boolean);
  }, [props.polygon, cellPosition]);

  const isLayer = pinned === true || pinned === false;
  const isSystem = formType && formType === 'system';

  const renderCounters = () => {
    if ((!layersCount && !linksCount && !countOneLevel) || isLayer) return null;
    return (
      <div className={scss.nt__counters}>
        <Counter svgClassName={scss.children} value={countOneLevel} />
        <Counter svgClassName={scss.links} value={linksCount} />
        <Counter svgClassName={scss.graphs} value={graphsCount} />
        <Counter svgClassName={scss.layers} value={layersCount} />
        <Counter svgClassName={scss.actors} value={actorsCount} />
        <Counter svgClassName={scss.tpls} value={tplsCount} />
      </div>
    );
  };

  const renderBalance = () => {
    if (!balance && balance !== 0) return null;
    return (
      <div className={scss.nt__counters}>
        <Counter
          className={scss.balance}
          svgClassName={scss[`vector_${balanceVector}`]}
          value={balanceFormatted || balance}
        />
      </div>
    );
  };

  const renderStatusColors = () => {
    if (!allColors || allColors.length < 3) return null;
    return (
      <div className={scss.nt__colors}>
        <ActorColors colors={allColors} type="compact" />
      </div>
    );
  };

  return (
    <div
      id={id}
      className={cn(className, scss.nt, {
        selected,
        semitransp,
        hide,
        [scss.polygon]: polygon,
      })}
      style={polygon ? { width } : {}}
      data-id={id}
    >
      {pictureObject || layerSettings.expand ? null : (
        <div className={scss.nt__wrap}>
          {renderBalance()}
          <div className={scss.nt__title}>
            <span
              dangerouslySetInnerHTML={{
                __html: AppUtils.bbCodeToHtml(Utils.stripHtml(title)),
              }}
            />
            {cr(
              [
                polygon && readOnly,
                <div className={scss.nt__title__readOnly} />,
              ],
              [layerSettings.pin, <div className={scss.nt__title__pinned} />]
            )}
          </div>
          <div className={scss.row}>
            {showNodesCoordinates && coordinates.length ? (
              <span className={scss.nt__cellPosition}>
                {coordinates
                  .filter(Boolean)
                  .map(({ x, y }) => `${x}, ${y}`)
                  .join('  :  ')}
              </span>
            ) : null}
            {renderCounters()}
          </div>
          {actorNumber ? (
            <div
              className={cn(scss.nt__num, {
                picture: Boolean(pictureObject || areaPicture),
              })}
            >
              {actorNumber}
            </div>
          ) : null}
        </div>
      )}
      {layerStarred ? <div className={scss.nt__flag} /> : null}
      {status ? (
        <div className={cn(scss.nt__status, scss.icon, scss[status])} />
      ) : null}
      {isSystem && pictureUrl ? (
        <div className={scss.nt__system__corner}>
          <div
            className={cn(
              scss.nt__system__corner__icon,
              cn(scss.icon, scss[formTitle])
            )}
          />
        </div>
      ) : null}
      {(isSystem && !pictureUrl && !layerSettings.expand && !polygon) ||
      iconClassName ? (
        <div
          className={cn(
            scss.nt__system,
            cn(scss.icon, iconClassName || scss[formTitle])
          )}
        />
      ) : null}
      {renderStatusColors()}
    </div>
  );
}

NodeTitle.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  linksCount: PropTypes.number,
  layerStarred: PropTypes.bool,
  selected: PropTypes.bool,
  semitransp: PropTypes.bool,
  hide: PropTypes.bool,
  readOnly: PropTypes.bool,
  polygon: PropTypes.bool,
  countOneLevel: PropTypes.number,
  balance: PropTypes.number,
  balanceVector: PropTypes.oneOf(['up', 'down']),
  status: PropTypes.oneOf(['verified', 'rejected', 'pending', null]),
  allColors: PropTypes.array,
  pinned: PropTypes.bool,
  pictureObject: PropTypes.object,
  areaPicture: PropTypes.object,
  cellPosition: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
  }),
};

export default NodeTitle;
