import { defineMessages } from 'react-intl';
import globalIntl from 'globalIntl'; // eslint-disable-line import/no-unresolved

const m = defineMessages({
  selectAccount: {
    id: 'selectAccount',
    defaultMessage: 'Select account',
  },
  noAccountsWithCurrency: {
    id: 'noAccountsWithCurrency',
    defaultMessage: 'No accounts with currency {currencyName}',
  },
});

export default { ...globalIntl, ...m };
